import React from 'react';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import {
  IconButton,
  Tooltip,
  Link
} from '@material-ui/core';
import Toolbar from './Toolbar';
import SidebarPrimary from './SidebarPrimary';
import SidebarHeader from './SidebarHeader';
import SidebarAction from './SidebarAction';
import {
  DashboardOutlined,
  StarBorder
} from '@material-ui/icons';
import {
  GYAAN_TAB_PAINEL,
  GYAAN_TAB_FAVORITOS,
  GYAAN_ROUTE_PAINEL,
  GYAAN_ROUTE_FAVORITOS
} from '../../utils/constants';
import useStyles from './style';
import { withTheme } from '@material-ui/styles';
import logo from '../../images/icone_gyaan_5.png';
/*
const teste = {
  logo: {
    primary: "https://sistema.mercadonarede.com.br/mnr-app/images/ic_launcher.png",
    secondary: "https://d9hhrg4mnvzow.cloudfront.net/www.mercadonarede.com/859d5dcd-mercado-na-rede-novidades.jpg"
  },
  user: {
    image: "https://pbs.twimg.com/profile_images/928297690965868545/N6QooZ_1_400x400.jpg"
  }
};

const Spacer = ({children}) => {
  const classes = useStyles();
  return (
    <div className={classes.spacer} />
  )
};
*/
export default withTheme(({tab, onChangeTab}) => {
  const classes = useStyles();

  // const actionItems = [
    // {title: 'Notificações', tab: GYAAN_TAB_NOTIFICACOES, icon: <NotificationsOutlined/>, onClick: handleOpenNotificationsPopover, to: null, anchorPopover},
    // {title: 'Painel', tab: GYAAN_TAB_PAINEL, icon: <DashboardOutlined/>, onClick: onChangeTab, to: GYAAN_ROUTE_PAINEL_RESUMO, anchorPopover: null},
    /*{title: 'Insights', tab: GYAAN_TAB_INSIGHTS, icon: <TableChartOutlined/>, onClick: onChangeTab},*/
    // {title: 'Favoritos', tab: GYAAN_TAB_FAVORITOS, icon: <StarBorder/>, onClick: onChangeTab, to: GYAAN_ROUTE_FAVORITOS, anchorPopover: null}
  // ];

  /*const navItems = [
    {label: 'Resumo', to: GYAAN_ROUTE_PAINEL_RESUMO, icon: <Assignment/>, value: GYAAN_TAB_PAINEL_RESUMO, onClick: onChangeSubTab},
    {label: 'Cotações', to: GYAAN_ROUTE_PAINEL_COTACOES, icon: <Copyright/>, value: GYAAN_TAB_PAINEL_COTACOES, onClick: onChangeSubTab},
    {label: 'Pedidos', to: GYAAN_ROUTE_PAINEL_PEDIDOS, icon: <Receipt/>, value: GYAAN_TAB_PAINEL_PEDIDOS, onClick: onChangeSubTab},
  ];*/

  return (
    <div className={classes.root}>
      <SidebarPrimary>
        <Toolbar>
          <SidebarHeader>
            <Link component={RouterLink} to={GYAAN_ROUTE_PAINEL}>
              <img src={logo} alt="Logo" style={{width: 45, height: 45}}/>
            </Link>
          </SidebarHeader>
          <SidebarAction>
            <Tooltip title="Painel" placement="right">
              <IconButton
                className={clsx(
                  classes.iconButton,
                  classes.marginBottom, tab === GYAAN_TAB_PAINEL && classes.iconButtonActive
                )}
                onClick={() => onChangeTab(GYAAN_TAB_PAINEL, GYAAN_ROUTE_PAINEL)}
                value={GYAAN_TAB_PAINEL}
              >
                <DashboardOutlined/>
              </IconButton>
            </Tooltip>
            <Tooltip title="Favoritos" placement="right">
              <IconButton
                className={clsx(
                  classes.iconButton,
                  classes.marginBottom, tab === GYAAN_TAB_FAVORITOS && classes.iconButtonActive
                )}
                onClick={() => onChangeTab(GYAAN_TAB_FAVORITOS, GYAAN_ROUTE_FAVORITOS)}
                value={GYAAN_TAB_FAVORITOS}
              >
                <StarBorder/>
              </IconButton>
            </Tooltip>
          </SidebarAction>
          {/*<Spacer/>
          <SidebarAction gutterBottom>
            <Tooltip title="Configurações" placement="right">
              <IconButton className={clsx(classes.iconButton, classes.marginBottom, tab === GYAAN_TAB_CONFIGURACOES && classes.iconButtonActive)} onClick={e => onChangeTab(e)} value={GYAAN_TAB_CONFIGURACOES}>
                <SettingsOutlined/>
              </IconButton>
            </Tooltip>
            <Tooltip title="Usuário" placement="right">
              <div
                aria-describedby={Boolean(anchorPopover) ? 'popover' : undefined}
                style={{width: 45, height: 45, borderRadius: '50%'}}
                onClick={e => handleOpenPopover(e)}
              >
                <img src={usuario.nm_arquivo} alt="Usuario" style={{width: '100%', borderRadius: '50%'}}/>
              </div>
            </Tooltip>
          </SidebarAction>*/}
        </Toolbar>
      </SidebarPrimary>
    </div>
  )
});
