import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles( theme => ({
  avatar: {
    backgroundColor: 'red'
  },
  grid: {
    [theme.breakpoints.up('sm')]: {
      margin: '38px -12px -12px -12px'
    }
  },
  gutterBottom: {
    marginBottom: 20
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around'
  },
  iconButton: {
    padding: 6
  },
  content: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    backgroundColor: 'white'
  },
  loading: {
    width: '80%',
    minHeight: 50
  }
}));

export default useStyles;