import React from 'react';
import useStyles from '../style';

export default ({children}) => {
  const classes = useStyles();
  return (
    <div className={classes.toolbar}>
      {children}
    </div>
  )
};