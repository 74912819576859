import React from 'react';
import {
  AppBar,
  Toolbar,
  Tooltip,
  Breadcrumbs,
  Link
} from '@material-ui/core';
import useStyles from "./style";
import {Link as RouterLink} from 'react-router-dom';
import {capitalize} from '../../utils/utils';

export default function TopBar({usuario, breadcrumbs, handleOpenUserPopover, anchorUser}) {
  const classes = useStyles();
  return (
    <>
      <AppBar position="absolute" className={classes.appBar}>
        <Toolbar style={{paddingLeft: 26, paddingRight: 35}}>
          <div className={classes.breadcrumbs}>
            <Breadcrumbs separator="•" aria-label="Breadcrumb">
              {breadcrumbs && breadcrumbs.length > 0 && breadcrumbs.map( breadcrumb => (
                <Link component={RouterLink} key={breadcrumb} color="inherit" to="#">
                  {capitalize(breadcrumb)}
                </Link>
              ))}
            </Breadcrumbs>
          </div>
          <Tooltip title="Usuário" placement="bottom">
            <div
              aria-describedby={Boolean(anchorUser) ? 'popover' : undefined}
              style={{width: 45, height: 45, borderRadius: '50%'}}
              onClick={e => handleOpenUserPopover(e)}
            >
              <img src={usuario.nm_arquivo} alt="Usuario" style={{width: '100%', borderRadius: '50%'}}/>
            </div>
          </Tooltip>
        </Toolbar>
      </AppBar>
    </>
  );
}