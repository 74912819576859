import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";

const ShareDialog = ({open, onClose, sharing, url}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{'Gyaan'}</DialogTitle>
      <DialogContent>
        {(sharing) ? (
          <>
            <DialogContentText>
              Aguarde enquanto processamos seu pedido.
            </DialogContentText>
            <LinearProgress />
          </>
        ) : (
          <DialogContentText>
            Processamento concluído com sucesso.
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button disabled={sharing} color="primary" onClick={()=>{
          if("share" in navigator)
            navigator
              .share({
                title: 'Gyaan',
                text: 'Acesse www.gyaan.com.br para maiores informações.',
                url: url
              })
              .then(onClose)
              .catch(onClose);
        }}>Compartilhar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShareDialog;