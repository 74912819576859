
export default () => {

  const getDataFromQuery = (host, headers, mode, {id_query, id_period, dt_start, dt_end, comparison=false}) => {
    return fetch(`${host}/queries/${id_query}/${id_period}/${dt_start}/${dt_end}?${comparison?'cptv':''}`, {method: 'GET', mode, headers})
      .then(response => {
        return response.json();
      })
      .catch(err => console.error(err))
  };

  self.addEventListener('message', e => { //eslint-disable-line no-restricted-globals
    if(!e)
      return;
    const {host, mode, headers, params} = e.data;
    getDataFromQuery(host, headers, mode, params)
      .then(response => {postMessage(response)})
      .catch(err => {postMessage(err)})
  });
};