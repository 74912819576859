import React from 'react';
import Chart from '../SimpleChart';
import PainelCard from '../../components/PainelCard';
import {GYAAN_QUERY_ITENS_COMPRADOS_USUARIO} from '../../utils/constants';
import {getImageSrc, labelFormatterHorizontal} from "../../utils/utils";

const ChartItensComprados = () => {
  return (
    <>
      <PainelCard
        variant="chart"
        id_query={GYAAN_QUERY_ITENS_COMPRADOS_USUARIO}
        headerProps={{enabled: false}}
        render={({data, loading}) => {
          return (
            <>
              {loading ? (
                <div style={{width: '100%', height: '100%', position: 'relative'}}>
                  <div style={{
                    position: 'absolute',
                    left: 50,
                    top: 80,
                    bottom: 50,
                    right: 50,
                    borderLeft: '1px solid #eeeeee'
                  }}>
                    <div className="animation-loading" style={{width: '100%', height: 30, marginBottom: 10}}/>
                    <div className="animation-loading" style={{width: '30%', height: 30, marginBottom: 10}}/>
                    <div className="animation-loading" style={{width: '50%', height: 30, marginBottom: 10}}/>
                    <div className="animation-loading" style={{width: '80%', height: 30, marginBottom: 10}}/>
                    <div className="animation-loading" style={{width: '80%', height: 30, marginBottom: 10}}/>
                    <div className="animation-loading" style={{width: '65%', height: 30, marginBottom: 10}}/>
                  </div>
                </div>
              ) : (
                <Chart
                  options={data}
                  labelFormatter={function () {
                    return labelFormatterHorizontal(getImageSrc(data.payload, this.pos), this.value === 'Não identificado' ? 'Anônimo' : this.value);
                  }}
                />
              )}
            </>
          )
        }}
      />
    </>
  );
};

export default ChartItensComprados;