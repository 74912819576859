import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles( theme => ({
  avatar: {
    backgroundColor: 'red'
  },
  first:{
    marginBottom: 4,
    [theme.breakpoints.down('sm')]: {
      marginLeft:-8,
    },
    [theme.breakpoints.up('sm')]:{
      marginTop: 38,
      marginLeft: -12,
      marginRight: 12,
      marginBottom: 4,
    },
  },
  gutterTop: {
    marginTop: 38
  },
  grid: {
    marginBottom:'7px',
    [theme.breakpoints.down('sm')]: {
      marginLeft:'-8px',
    },
    [theme.breakpoints.up('sm')]:{
      marginLeft:'-12px',
      marginRight:'-12px',
      // marginBottom:'4px',
    },
    /*marginTop:'-4px',
    marginLeft:'-12px',
    marginRight:'-12px'*/
  },
  gutterBottom: {
    marginBottom: 20
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around'
  },
  iconButton: {
    padding: 6
  },
  content: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    backgroundColor: 'white'
  },
  loading: {
    width: '80%',
    minHeight: 50
  }
}));

export default useStyles;