import React from 'react';
import PropTypes from 'prop-types';
import {List, ListItem, ListItemText, Menu, MenuItem} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {GYAAN_PERIODO_SETE_DIAS, GYAAN_PERIODOS} from "../../utils/constants";
import {getTextFromPeriod} from "../../utils/utils";

const useStyles = makeStyles({
  menuItem: {
    minHeight: 0,
  }
});

const Period = ({anchorEl=null, period=GYAAN_PERIODO_SETE_DIAS, onSelect=()=>{}, onOpen=()=>{}, onClose=()=>{}}) => {
  const classes = useStyles();
  return (
    <>
      <List component="a">
        <ListItem
          button
          aria-controls="menu-periodo"
          aria-haspopup="true"
          onClick={onOpen}
          style={{
            padding: 0,
            marginTop: 0,
            marginBottom: 0
          }}
        >
          <ListItemText secondary={getTextFromPeriod(period)} />
        </ListItem>
      </List>
      {Boolean(anchorEl) && (
        <Menu
          id="menu-periodo"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={onClose}
        >
          {GYAAN_PERIODOS.map(([value, text]) =>
            <MenuItem
              key={value}
              selected={value===period}
              onClick={() => onSelect(value)}
              className={classes.menuItem}
            >
              {text}
            </MenuItem>)
          };
        </Menu>
      )}
    </>
  )
};

Period.propTypes = {
  anchorEl: PropTypes.object,
  period: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default Period;