import React from 'react';
import PropTypes from 'prop-types';
import { ProgressWidget } from '../Widgets';
import NoData from '../NoData';
import RankingLoading from "./RankingLoading";
import useStyles from "./style";
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction
} from '@material-ui/core';

const Ranking = ({ranking, colors, loading, reverseValues, pagination, page}) => {
  const classes = useStyles();
  let i = 0;
  const data = (pagination) ? ranking[page] : ranking;
  return (
    <>
      <List component="ul">
        {data && data.length > 0 && data.map(({label, value, percent}, index) => {
          const color = colors[i];
          i++;
          if (i === colors.length - 1)
            i = 0;
          return (
            <ListItem className={classes.listItem} key={index} component="li">
              <ListItemText className={classes.listItemText} primary={label} />
              <ListItemSecondaryAction className={classes.listItemWidget}>
                <ProgressWidget
                  total={value}
                  percent={percent}
                  color={color}
                  reverseValues={reverseValues}
                  inline
                />
              </ListItemSecondaryAction>
            </ListItem>
          )
        })}
      </List>
      <RankingLoading loading={loading}/>
      {!loading && !ranking && !ranking.length > 0 && (
        <NoData type="ranking" />
      )}
    </>
  )
};

Ranking.propTypes = {
  title: PropTypes.string,
  colors: PropTypes.array,
  ranking: PropTypes.array.isRequired
};

Ranking.defaultProps = {
  title: '',
  ranking: [],
  colors: ['#0D47A1','#1565C0','#1976D2','#1E88E5','#2196F3','#42A5F5','#64B5F6','#90CAF9','#BBDEFB','#E3F2FD']
};

export default Ranking;