import React from 'react';
import Loading from '../Loading';
import PainelCard from '../PainelCard';
import {GYAAN_QUERY_DRILL_FORNECEDORES} from "../../utils/constants";
import Chart from "../SimpleChart";

const ChartVolumeComprasDrillFornecedores = () => {
  return (
    <>
      <PainelCard
        variant="chart"
        id_query={GYAAN_QUERY_DRILL_FORNECEDORES}
        headerProps={{enabled: false}}
        render={({data, loading}) => {
          return (
            <>
              {loading ? (<Loading />) : (<Chart options={data}/>)}
            </>
          )
        }}
      />
    </>
  );
};

export default ChartVolumeComprasDrillFornecedores;