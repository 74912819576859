import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles( theme => ({
  'card-ranking-primary': {
    height: 20
  },
  'card-ranking-secondary': {
    height: 20,
    flexBasis: `20%`,
    maxWidth: `20%`,
    width: `20%`
  }
}));

export default useStyles;