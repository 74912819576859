import React from "react";
import Loading from '../Loading';
import PainelCard from "../PainelCard";
import Chart from "../SimpleChart";
import {GYAAN_QUERY_COMPRAS_DIARIAS_EMPRESAS} from "../../utils/constants";

const ChartComprasDiariasPorEmpresa = () => {
  return (
    <>
      <PainelCard
        variant="chart"
        id_query={GYAAN_QUERY_COMPRAS_DIARIAS_EMPRESAS}
        headerProps={{enabled: false}}
        render={({data, loading}) => {
          return (
            <>
              {loading ? (<Loading/>) : (<Chart options={data}/>)}
            </>
          )
        }}
      />
  </>);
};

export default ChartComprasDiariasPorEmpresa;