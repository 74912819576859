import React from 'react';
import MiniContent from '../MiniContent';
import PainelCard from '../PainelCard';
import {
  GYAAN_QUERY_RESUMO_COMPRAS,
  STRING_CURRENCY_SYMBOL
} from "../../utils/constants";
import {
  mathVariance,
  formatCurrency
} from '../../utils/utils';

const SummaryComprasDiarias = () => {
  return (
    <PainelCard
      variant="mini"
      title="Total Comprado"
      id_query={GYAAN_QUERY_RESUMO_COMPRAS}
      share={false}
      compare={true}
      render={({data, loading}) => {
        const {total=0, total_previous=0} = data && data.length ? data[0] : {};
        return (
          <MiniContent
            loading={loading}
            primary={formatCurrency(total)}
            currency={STRING_CURRENCY_SYMBOL}
            secondary={`${formatCurrency(total_previous)} (${mathVariance(total_previous, total).toFixed(2)}%)`}
            secondaryCurrency={STRING_CURRENCY_SYMBOL}
          />
        )
      }}
    />
  );
};

export default SummaryComprasDiarias;