import React from 'react';
import {
  GYAAN_CHART_SUBTITLE_ZOOM_DESKTOP,
  GYAAN_CHART_SUBTITLE_ZOOM_MOBILE,
  HIGHCHART_OPTIONS,
} from '../../utils/constants';
import PropTypes from 'prop-types';
import useStyles from "./style";
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
require("highcharts/modules/offline-exporting")(Highcharts);
require("highcharts/modules/drilldown")(Highcharts);
require("highcharts/modules/stock")(Highcharts);

Highcharts.setOptions(HIGHCHART_OPTIONS);

const SimpleChart = ({options, labelFormatter, tooltipFormatter}) => {
  const classes = useStyles();
  const chartOptions = options;

  if(chartOptions) {
    if (chartOptions.chart.zoomType)
      chartOptions.subtitle.text = document.ontouchstart
        ? GYAAN_CHART_SUBTITLE_ZOOM_MOBILE
        : GYAAN_CHART_SUBTITLE_ZOOM_DESKTOP;
    else
      chartOptions.subtitle.text = '';

    if (labelFormatter)
      chartOptions.xAxis.labels.formatter = labelFormatter;

    if (tooltipFormatter)
      chartOptions.tooltip.formatter = tooltipFormatter;

  }

  return (
    <div className={classes.root}>
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
        allowChartUpdate={true}
        updateArgs={[true, true, true]}
      />
    </div>
  )
};

SimpleChart.propTypes = {
  options: PropTypes.object.isRequired,
  labelFormatter: PropTypes.func,
  tooltipFormatter: PropTypes.func
};

export default SimpleChart;