import React from 'react';
import PropTypes from 'prop-types';
import {
  Tooltip,
  Typography
} from '@material-ui/core';
import useStyles from "./style";
import { grey } from '@material-ui/core/colors';
import { getTextColorByBrightness } from '../../../utils/utils';

const ProgressWidget = ({total, percent, placement, inline, color, reverseValues, decimals}) => {

  const textColor = getTextColorByBrightness(color);
  const classes = useStyles({inline, color, textColor});

  let value = total, valueP = percent;

  /*if (!reverseValues) {
    value = total;
  }*/

  if (reverseValues) {
    value = percent;
    valueP = total;
  }

  if (typeof value === 'number')
    value = parseFloat(value.toFixed(decimals));
  if (typeof valueP === 'number')
    valueP = parseFloat(valueP.toFixed(decimals));

  if (valueP === percent)
    valueP += '%';
  if (value === percent)
    value += '%';

  if (typeof percent === 'number')
    percent = `${percent.toFixed(2)}%`;

  return (
    <div className={classes.root}>
      <Typography className={classes.percent} align="right" color="textSecondary">
        {value}
      </Typography>
      <div className={classes.widgetContainer}>
        <Tooltip classes={{tooltip: classes.tooltip}} title={valueP} placement={placement}>
          <div
            className={classes.widget}
            style={{
              backgroundImage: `linear-gradient(to right, ${color} ${percent}, ${grey[300]} 0%)`,
            }}
          />
        </Tooltip>
      </div>
    </div>
  )
};

ProgressWidget.propTypes = {
  total: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  percent: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  placement: PropTypes.string,
  decimals: PropTypes.number
};

ProgressWidget.defaultProps = {
  placement: 'left',
  decimals: 2
};

export { ProgressWidget };