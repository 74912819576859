import React from 'react';
import MiniContent from "../MiniContent";
import PainelCard from '../PainelCard';
import {
  GYAAN_QUERY_RESUMO_GRUPO,
  STRING_CURRENCY_SYMBOL,
} from '../../utils/constants';

const SummaryComprasGrupos = () =>
  <PainelCard
    variant="mini"
    title="Grupo Campeão"
    share={false}
    id_query={GYAAN_QUERY_RESUMO_GRUPO}
    render={({data, loading}) => {
      const {label='', total=0, percent=0} = data && data.length ? data[0] : {};
      return (
        <MiniContent
          loading={loading}
          primary={label}
          secondary={`${total} (${percent}%)`}
          secondaryCurrency={STRING_CURRENCY_SYMBOL}
        />
      )
    }}
  />;

export default SummaryComprasGrupos;