import React from 'react';
import Chart from '../SimpleChart';
import {GYAAN_QUERY_COMPRAS_USUARIO} from "../../utils/constants";
import PainelCard from '../PainelCard';
import {getImageSrc, labelFormatterVertical} from "../../utils/utils";

const ChartVolumeCompras = () => {
  return (
    <>
      <PainelCard
        variant="chart"
        id_query={GYAAN_QUERY_COMPRAS_USUARIO}
        headerProps={{enabled: false}}
        render={({data, loading}) => {
          return (
            <>
              {loading ? (
                <div style={{width: '100%', height: '70%', position: 'relative'}}>
                  <div style={{position: 'absolute', left: 50, top: 50, bottom: 50, right: 50, borderLeft: '1px solid #eeeeee', borderBottom: '1px solid #eeeeee'}}>
                    <div className="animation-loading" style={{width: '100%', height: 30, marginBottom: 10}}/>
                    <div className="animation-loading" style={{width: '30%', height: 30, marginBottom: 10}}/>
                    <div className="animation-loading" style={{width: '50%', height: 30, marginBottom: 10}}/>
                    <div className="animation-loading" style={{width: '80%', height: 30, marginBottom: 10}}/>
                    <div className="animation-loading" style={{width: '80%', height: 30, marginBottom: 10}}/>
                    <div className="animation-loading" style={{width: '65%', height: 30, marginBottom: 10}}/>
                  </div>
                </div>
              ) : (
                <Chart
                  options={data}
                  labelFormatter={function() {
                    return labelFormatterVertical(getImageSrc(data.payload, this.pos), this.value);
                  }}
                />
              )}
            </>
          )
        }}
      />
    </>
  )
};

export default ChartVolumeCompras;
