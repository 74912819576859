import {makeStyles} from "@material-ui/core";
import {GYAAN_COLOR_PRIMARY, GYAAN_COLOR_SECONDARY, GYAAN_SIDEBAR_WIDTH} from "../../utils/constants";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  popoverPaper: {
    width: 250,
    height: 200
  },
  appBar: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    width: `calc(100% - ${GYAAN_SIDEBAR_WIDTH}px)`
  },
  breadcrumbs: {
    flexGrow: 1
  },
  primary: {
    backgroundColor: GYAAN_COLOR_PRIMARY
  },
  primaryMobile: {
    backgroundColor: GYAAN_COLOR_SECONDARY
  },
  appBarMobile: {
    backgroundColor: GYAAN_COLOR_PRIMARY
  }
}));

export default useStyles;