import React from 'react';
import {SwipeableDrawer} from '@material-ui/core';
import {
  DashboardOutlined,
  StarBorder,
  ExitToApp
} from '@material-ui/icons';
import {
  GYAAN_ROUTE_FAVORITOS,
  GYAAN_ROUTE_PAINEL,
  GYAAN_TAB_FAVORITOS,
  GYAAN_TAB_LOGOUT,
  GYAAN_TAB_PAINEL,
  iOS
} from '../../utils/constants';
import DrawerList from "./DrawerList";
import DrawerHeader from "./DrawerHeader";

export default function Drawer({open, tab, onToggle, onChangeTab, usuario, onLogout}) {

  const actionItems = [
    /*{title: 'Notificações', tab: GYAAN_TAB_NOTIFICACOES, icon: <NotificationsOutlined/>, onClick: onChangeTab},*/
    {title: 'Painel', tab: GYAAN_TAB_PAINEL, icon: <DashboardOutlined/>, onClick: ()=>onChangeTab(GYAAN_TAB_PAINEL, GYAAN_ROUTE_PAINEL)},
    /*{title: 'Insights', tab: GYAAN_TAB_INSIGHTS, icon: <TableChartOutlined/>, onClick: onChangeTab},*/
    {title: 'Favoritos', tab: GYAAN_TAB_FAVORITOS, icon: <StarBorder/>, onClick: ()=>onChangeTab(GYAAN_TAB_FAVORITOS, GYAAN_ROUTE_FAVORITOS)},
    // {title: 'Perfil', tab: GYAAN_TAB_PERFIL, icon: <Portrait/>, onClick: onChangeTab},
    {title: 'Sair', tab: GYAAN_TAB_LOGOUT, icon: <ExitToApp style={{transform: 'rotate(180deg)'}}/>, onClick: onLogout}
  ];

  return (
      <SwipeableDrawer
        open={open}
        onClose={onToggle(false)}
        onOpen={onToggle(true)}
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
      >
        <DrawerHeader
          username={usuario.nm_usuario}
          photo={usuario.nm_arquivo}
          company={usuario.nm_fantasia}
        />
        <DrawerList
          items={actionItems}
          tab={tab}
          onToggle={onToggle}
        />
      </SwipeableDrawer>
  );
}
