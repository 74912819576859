import React from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText
} from '@material-ui/core';
import TextField from '../TextField';
import {GYAAN_COLOR_PRIMARY_LIGHT} from '../../utils/constants';

export default function ModalChangePassword({open, onClose}) {
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="password-dialog"
        fullWidth
      >
        <DialogTitle id="password-dialog">Esqueceu sua senha?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Não se preocupe, é bem fácil. Basta colocar sua senha nova e confirmá-la.
          </DialogContentText>
          <TextField
            type="password"
            id="new_senha"
            label="Senha"
            margin="dense"
            variant="outlined"
            autoFocus
            fullWidth
          />
          <TextField
            type="password"
            label="Confirme sua senha"
            margin="dense"
            variant="outlined"
            fullWidth
            style={{marginTop: 10}}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} style={{color: GYAAN_COLOR_PRIMARY_LIGHT}}>
            Fechar
          </Button>
          <Button onClick={() => alert("Ok!")} style={{color: GYAAN_COLOR_PRIMARY_LIGHT}}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}