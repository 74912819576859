import React from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";
import useStyles from "./style";

export default () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CircularProgress/>
    </div>
  );
};