import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { GYAAN_CHART_HEIGHT } from '../../utils/constants';

const useStyles = makeStyles({
  chart: {
    height: GYAAN_CHART_HEIGHT
  },
  ranking: {
    height: GYAAN_CHART_HEIGHT,
    maxHeight: 'calc(100% - 100px)'
  },
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  customHeight: height => height
});

export default function NoData({type, height}) {

  const classes = useStyles(height);

  return (
    <div className={clsx(classes.container, {
      [classes.chart]: type === 'chart',
      [classes.ranking]: type === 'ranking',
      [classes.customHeight]: height
    })}>
      <div>No data</div>
    </div>
  )
}

NoData.propTypes = {
  type: PropTypes.oneOf(['chart', 'ranking']),
  height: PropTypes.number
};