import React, { useEffect } from 'react';
import {messaging} from "../../firebase-init";
import { Grid } from '@material-ui/core';
import useStyles from "./style";

import ViewWrapper from '../../components/ViewWrapper';
import ChartItensComprados from '../../components/ChartItensComprados';
import ChartItensCotados from '../../components/ChartItensCotados';
import ChartVolumeCompras from '../../components/ChartVolumeCompras';
import ChartEvolucaoCompras from '../../components/ChartEvolucaoCompras';
import ChartComprasDiarias from '../../components/ChartComprasDiarias';
import ChartComprasDiariasPorEmpresa from '../../components/ChartComprasDiariasPorEmpresa';
import ChartComprasFornecedores from '../../components/ChartComprasFornecedores';
import ChartComprasGrupos from '../../components/ChartComprasGrupos';
import ChartItensNaoCotados from '../../components/ChartItensNaoCotados';
import ChartVolumeComprasDrillFornecedores from "../../components/ChartVolumeComprasDrillFornecedores";
import ChartVolumeComprasDrillGrupos from "../../components/ChartVolumeComprasDrillGrupos";
import SummaryComprasDiarias from '../../components/SummaryComprasDiarias';
import SummaryComprasGrupos from '../../components/SummaryComprasGrupos';
import SummaryComprasFornecedor from '../../components/SummaryComprasFornecedor';
import RankingGrupos from '../../components/RankingGrupos';
import RankingMarcas from '../../components/RankingMarcas';
import ChartCompradores from '../../components/ChartCompradores';
import {registerFirebaseServiceWorker, useUser} from '../../utils/utils';

export default function ({history, match, usuario, onLogout}) {

  const classes = useStyles();
  const user = useUser();

  useEffect(()=>{
    if(messaging)
      registerFirebaseServiceWorker(messaging);
  }, []);

  return (
    <>
      <ViewWrapper history={history} match={match} usuario={usuario} onLogout={onLogout}>
        <Grid container spacing={2} className={classes.grid}>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <SummaryComprasDiarias />
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <SummaryComprasFornecedor />
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <SummaryComprasGrupos />
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <RankingGrupos/>
          </Grid>
          {user !== null && user.tl_usuarios_compradores > 1 ? (
            <>
              {/*<Grid item lg={8} md={8} sm={12} xs={12}>
                <ChartItensComprados />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <ChartItensCotados />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <ChartVolumeCompras />
              </Grid>*/}
              <Grid item lg={8} md={12} sm={12} xs={12}>
                <ChartEvolucaoCompras />
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <RankingMarcas/>
              </Grid>
              <Grid item lg={8} md={8} sm={12} xs={12}>
                <ChartItensNaoCotados />
              </Grid>
            </>
          ) : (
            <>
              <Grid item lg={8} md={8} sm={12} xs={12}>
                <ChartItensNaoCotados />
              </Grid>
            </>
          )}
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <ChartComprasGrupos />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <ChartComprasFornecedores />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <ChartComprasDiarias />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <ChartComprasDiariasPorEmpresa />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <ChartVolumeComprasDrillFornecedores />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <ChartVolumeComprasDrillGrupos />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <ChartCompradores />
          </Grid>
        </Grid>
      </ViewWrapper>
    </>
  )
}
