import React from 'react';
import PropTypes from 'prop-types';

import MiniContentBackground from './MiniContentBackground';
import MiniContentLoading from './MiniContentLoading';
import MiniContentPrimary from './MiniContentPrimary';
import MiniContentSecondary from './MiniContentSecondary';
import useStyles from './style';

//TODO: Arrumar padding quando tem ou não tem currency

/*
* 1º: 1 linha
* 2º: 2 linhas
* 3º: 1 linha + widget
* 4º: 2 linhas + widget
* */

const MiniContent = ({primary, secondary, currency, secondaryCurrency, loading}) => {
  const classes = useStyles();
  return(
    <>
      <MiniContentLoading classes={classes} loading={loading} primary={primary} secondary={secondary} />
      <MiniContentBackground classes={classes} loading={loading} />
      <MiniContentPrimary classes={classes} loading={loading} primary={primary} currency={currency} />
      <MiniContentSecondary classes={classes} loading={loading} secondary={secondary} currency={secondaryCurrency} />
    </>
  );
};

MiniContent.propTypes = {
  title: PropTypes.string,
  primary: PropTypes.any.isRequired,
  secondary: PropTypes.string,
  currency: PropTypes.string,
  loading: PropTypes.bool
};

MiniContent.defaultProps = {
  title: '',
  primary: '',
  secondary: '',
  currency: '',
  loading: true
};

export default MiniContent;