import React, {useState} from 'react';
import { withRouter, Route, Switch } from 'react-router-dom';
import AuthRoute from './components/AuthRoute';
import ResumoView from './views/ResumoView';
import LoginView from './views/LoginView';
import FavoriteView from './views/FavoriteView';
import {
  GYAAN_ROUTE_LOGIN,
  GYAAN_ROUTE_PAINEL,
  GYAAN_STORAGE_ITEM_TOKEN,
  GYAAN_STORAGE_ITEM_USER,
  GYAAN_STORAGE_ITEM_NAME,
  GYAAN_STORAGE_ITEM_CNPJ,
  GYAAN_STORAGE_ITEM_PHOTO,
  GYAAN_ROUTE_FAVORITOS,
} from './utils/constants';
import { useUser } from './utils/utils';
import UserProvider from "./UserProvider";

export default withRouter(props => {

  const [usuario, setUsuario] = useState(useUser());
  const [token, setToken] = useState(sessionStorage.getItem(GYAAN_STORAGE_ITEM_TOKEN));

  const setLoggedUser = user => {
    setUsuario(user);
    if(user===null)
      sessionStorage.removeItem(GYAAN_STORAGE_ITEM_USER);
    else
      sessionStorage.setItem(GYAAN_STORAGE_ITEM_USER, JSON.stringify(user));
  };

  const setLoggedToken = token => {
    setToken(token);
    if(token===null)
      sessionStorage.removeItem(GYAAN_STORAGE_ITEM_TOKEN);
    else
      sessionStorage.setItem(GYAAN_STORAGE_ITEM_TOKEN, token);
  };

  const handleLogin = ({payload}) => {
    const { token, usuario } = payload;
    setLoggedToken(token);
    setLoggedUser(usuario); 
    localStorage.setItem(GYAAN_STORAGE_ITEM_NAME, usuario.nm_usuario);
    localStorage.setItem(GYAAN_STORAGE_ITEM_CNPJ, usuario.nr_cnpj);
    localStorage.setItem(GYAAN_STORAGE_ITEM_PHOTO, usuario.nm_arquivo);
    props.history.push(GYAAN_ROUTE_PAINEL);
  };

  const handleLogout = () => {
    setLoggedToken(null);
    setLoggedUser(null);
  };

  return (
    <>
      <Switch>
        <Route
          exact path={GYAAN_ROUTE_LOGIN}
          render={({match}) => <LoginView onLogin={handleLogin} />}
        />
        <UserProvider>
          <AuthRoute
            exact path={GYAAN_ROUTE_PAINEL}
            component={ResumoView}
            usuario={usuario}
            token={token}
            onLogout={handleLogout}
          />
          <AuthRoute
            exact path={GYAAN_ROUTE_FAVORITOS}
            component={FavoriteView}
            usuario={usuario}
            token={token}
            onLogout={handleLogout}
          />
        </UserProvider>
      </Switch>
    </>
  )
});
