import {makeStyles} from '@material-ui/core';
import {grey} from '@material-ui/core/colors';

const useStyles = makeStyles({
  'mini-container': {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  'mini-content': {
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  'mini-content__primary': {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 15
  },
  'mini-content__secondary': {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    paddingLeft: 15,
    paddingRight: 15
  },
  bgImageContainer: {
    width: '100%',
    height: '100%',
    zIndex: 1,
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bgImage: {
    opacity: 0.1,
    width: '40%',
    height: '150%'
  },
  'card-mini-prefix': {
    fontSize: '1rem',
    marginTop: 4
  },
  'card-mini-prefix__lighter': {
    color: grey[600]
  },
  'card-mini-primary': {
    // paddingLeft: 15,
    paddingRight: 30,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '1.5rem',
  },
  'card-mini-loading__primary': {
    width: '80%',
    minHeight: 50,
    marginBottom: 10
  },
  'card-mini-loading__secondary': {
    width: '45%',
    minHeight: 25
  }
});

export default useStyles;