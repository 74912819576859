import React from 'react';
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Popover,
  Divider
} from '@material-ui/core';
import {
  Backup,
  Star
} from '@material-ui/icons';
import useStyles from "./style";
import IconGyaan from '../../images/icone_gyaan_5.png'

export default ({anchorEl, usuario, onClose, onLogout}) => {
  const classes = useStyles();

  const anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'center',
  };
  const transformOrigin = {
    vertical: 'top',
    horizontal: 'center'
  };

  return (
    <Popover
      id="notifications-popover"
      classes={{
        paper: classes.popoverPaper
      }}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      onClose={onClose}
      disableRestoreFocus
    >
      <Typography component="div" className={classes.title}>
        Notificações
      </Typography>
      <List component="ul" className={classes.list}>
        <ListItem component="li" className={classes.item} onClick={() => onClose()}>
          <ListItemAvatar>
            <Avatar component="div">
              <Star style={{color: 'yellow'}}/>
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="Ei! Seu total comprado teve uma economia em relação ao período anterior!"
            primaryTypographyProps={{className: classes.textPrimary}}
            secondary="20/08/2019 11:30"
          />
        </ListItem>
        <Divider light/>
        <ListItem component="li" className={classes.item} onClick={() => onClose()}>
          <ListItemAvatar>
            <Avatar component="div">
              <Backup/>
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="Backup concluído com sucesso"
            primaryTypographyProps={{className: classes.textPrimary}}
            secondary="20/08/2019 06:18"
          />
        </ListItem>
        <Divider light/>
        <ListItem component="li" className={classes.item} onClick={() => onClose()}>
          <ListItemAvatar>
            <Avatar component="div" src={IconGyaan}/>
          </ListItemAvatar>
          <ListItemText
            primary={`Bem-vindo ao GYAAN, ${usuario.nm_usuario}`}
            secondary="19/08/2019 12:35"
          />
        </ListItem>
      </List>
    </Popover>
  )
};