import {makeStyles} from "@material-ui/core";
import {grey, yellow} from "@material-ui/core/colors";

const useStyles = makeStyles( theme => ({
  paper: {
    height: 500,
  },
  header: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: 15,
    paddingRight: 15,
    height: '10%',
  },
  headerTitle: {
    flexGrow: 1
  },
  action: {
    padding: 6
  },
  actionIcon: {
    color: yellow[700]
  },
  content: {
    paddingLeft: 10,
    paddingRight: 10,
    position: 'relative',
    flexGrow: 1,
    overflow: 'auto'
  },
  bordered: {
    borderBottom: `1px solid ${grey[300]}`
  },
  loading: {
    border: '1px solid black',
  },
  listItem: {
    paddingTop: 5,
    paddingBottom: 5,
    transition: theme.transitions.create('background-color', {
      duration: theme.transitions.duration.standard
    }),
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, .02)'
    }
  },
  listItemWidget: {
    width: '50%'
  },
  listItemText: {
    maxWidth: '50%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    marginTop: 0,
    marginBottom: 0
  }
}));

export default useStyles;