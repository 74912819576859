import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import {
  Paper, CircularProgress, withWidth
} from '@material-ui/core';
import {
  GYAAN_CHART_HEIGHT,
  GYAAN_MESSAGE_WORK_IN_PROGRESS,
  GYAAN_PAGINATION_RANKING_LIMIT,
  GYAAN_QUERY_RESUMO_COMPRAS,
  GYAAN_STATUS_RESPONSE_NO_LENGTH,
  GYAAN_STATUS_RESPONSE_OK,
} from '../../utils/constants';
import {formatDate, getPeriod, useFavorite, usePeriod, setSessionFavorite} from '../../utils/utils';

import useStyles from './style.js';
import {getCachedDataFromQuery, getDataFromQuery, postShare, putFavorite} from '../../utils/api';
import useQuery from "../../utils/useQuery";

import PainelCardContent from './PainelCardContent';
import PainelCardHeader from './PainelCardHeader';
import PainelCardFooter from './PainelCardFooter';
import UserContext from "../../UserContext";
import ShareDialog from "../ShareDialog";

//TODO: Renomear prop id_query
const PainelCard = ({title, headerProps, footerProps, children, variant, render, id_query, width, compare, share}) => {
  const ref = React.useRef(null);
  const classes = useStyles();
  const id_period = usePeriod(id_query);

  const [url_sharing, setUrlSharing] = useState(null);
  const [open_sharing, setOpenSharing] = useState(false);
  const [sharing, setSharing] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [favorite, setFavorite] = useState(useFavorite(id_query));
  const [menuItem, setMenuItem] = useState(id_period);

  const [data, setData] = useState([]);
  const [comparison, setComparison] = useState(false);
  const [status, setStatus] = useState(GYAAN_STATUS_RESPONSE_NO_LENGTH);
  const [loading, setLoading] = useState(true);
  const [isPeriodChanging, setIsPeriodChanging] = useState(false);

  const [anchorMenu, setAnchorMenu] = useState(null);
  const [chartRef, setChartRef] = useState(null);

  const [page, setPage] = React.useState(0);

  const { enqueueSnackbar } = useSnackbar();
  const {changeUser} = useContext(UserContext);

  const {start, end} = getPeriod(id_period);
  useQuery(id_query, id_period, start, end, compare)(setData, setLoading, setStatus, payload => {
    setData(payload);
  });

  const handleClickPeriod = e => {
    setAnchorEl(e.currentTarget);
  };

  const handleClosePeriod = () => {
    setAnchorEl(null);
  };

  const handleComparison = () => {
    getDataFromQuery({id_query: id_query, id_period: menuItem, dt_start: formatDate(start), dt_end: formatDate(end), comparison:!comparison})
      .then(({status=null, payload=[]}={}) => {
        if(status!== null) {
          setData(payload);
          setStatus(payload.length > 0 ? GYAAN_STATUS_RESPONSE_OK : GYAAN_STATUS_RESPONSE_NO_LENGTH)
        }
      });
    setComparison(!comparison);
  };

  const handleChangePeriod = item => {
    let is_request = false;
    const { start, end } = getPeriod(item);
    setIsPeriodChanging(true);

    if("caches" in window)
      getCachedDataFromQuery({id_query:id_query, id_period:item, dt_start: formatDate(start), dt_end: formatDate(end)})
        .then(result => {
          if(result && result.status && result.payload && !is_request){
            setStatus(result.status === GYAAN_STATUS_RESPONSE_OK ? GYAAN_STATUS_RESPONSE_OK : GYAAN_STATUS_RESPONSE_NO_LENGTH);
            setData(result.payload);
          }
        }).catch(err => console.error(err));

    getDataFromQuery({id_query: id_query, id_period: item, dt_start: formatDate(start), dt_end: formatDate(end), comparison:id_query===GYAAN_QUERY_RESUMO_COMPRAS})
      .then(({status=null, payload=[]}={}) => {
        is_request = true;
        setIsPeriodChanging(false);
        if(status!== null) {
          setData(payload);
          setStatus(payload.length > 0 ? GYAAN_STATUS_RESPONSE_OK : GYAAN_STATUS_RESPONSE_NO_LENGTH)
        }
      });
    setMenuItem(item);
    setAnchorEl(null);
  };

  const handleFavorite = e => {
    let fav = null;
    switch(e.target.tagName) {
      case "svg":
        fav = e.target.parentElement.parentElement.dataset.favorite;
        break;
      case "path":
        fav = e.target.parentElement.parentElement.parentElement.dataset.favorite;
        break;
      default:
        fav = e.target.dataset.favorite;
    }
    setFavorite(fav !== 'true');
    putFavorite(id_query, fav !== 'true')
      .then(response => {
        const user = setSessionFavorite(id_query, fav !== 'true');
        if(user)
          changeUser(user);
      })
  };

  const handleOpenMenu = e => {
    const options = {
      variant: 'warning',
      autoHideDuration: 3000,
      dense: width === 'xs' || 'sm'
    };
    enqueueSnackbar(GYAAN_MESSAGE_WORK_IN_PROGRESS, options);
    // setAnchorMenu(e.currentTarget);
  };

  const handleCloseMenu = () =>
    setAnchorMenu(null);

  const handleDownload = (chart, type) => {
    switch(type) {
      case 'png':
        chart.exportChartLocal({
          type: 'image/png'
        });
        break;
      case 'xls':
        chart.downloadXLS();
        break;
      case 'csv':
        chart.downloadCSV();
        break;
      default:
        alert('Atenção, opção desconhecida.');
    }
  };

  const getRef = ref => setChartRef(ref);

  const handleNext = () => {
    setPage(prevPage => prevPage + 1);
  };

  const handleBack = () => {
    setPage(prevPage => prevPage - 1);
  };

  const maxPages = Math.round(data.length / GYAAN_PAGINATION_RANKING_LIMIT);
  let pages = [];
  if (headerProps.pagination && data)
    for (let i = 0; i < maxPages; i++)
      pages.push(data.slice(GYAAN_PAGINATION_RANKING_LIMIT * i,
        GYAAN_PAGINATION_RANKING_LIMIT * (i + 1)));

  return (
    <>
      <ShareDialog
        url={url_sharing}
        open={open_sharing}
        sharing={sharing}
        onClose={()=>setOpenSharing(false)}
      />
      <Paper
        ref={ref}
        className={clsx({
          [classes['card-mini-paper']]: variant === 'mini',
          [classes['card-ranking-paper']]: variant === 'ranking',
          [classes['card-chart-paper']]: variant === 'chart'
        })}
      >
        {(isPeriodChanging) && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: 10,
              backgroundColor: 'rgba(222, 222, 222, .3)',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              alignItems: 'center'
            }}
          >
            <div style={{padding: 10}}>
              <CircularProgress classes={{root: classes.root}} size={26} disableShrink/>
            </div>
          </div>
        )}
        {headerProps.enabled && (
          <PainelCardHeader
            loading={loading}
            title={title}
            bordered={headerProps.bordered}
            pagination={headerProps.pagination}
            page={page}
            maxPages={maxPages}
            onNext={handleNext}
            onBack={handleBack}
            chartRef={chartRef}
            anchorMenu={anchorMenu}
            onOpenMenu={handleOpenMenu}
            onCloseMenu={handleCloseMenu}
            onDownload={handleDownload}
          />
        )}
        <PainelCardContent variant={variant}>
          {render && render({data, pages, page, loading, status, getRef, comparison, ref: ref.current})}
          {children && children}
        </PainelCardContent>
        {footerProps.enabled && (
          <PainelCardFooter
            {...footerProps}
            anchorEl={anchorEl}
            period={menuItem}
            favorite={favorite}
            comparison={comparison}
            onFavorite={handleFavorite}
            onOpenPeriod={handleClickPeriod}
            onClosePeriod={handleClosePeriod}
            onSelectPeriod={handleChangePeriod}
            onComparison={handleComparison}
            shareEnabled={share && data!==null}
            onShare={()=>{
              setOpenSharing(true);
              setSharing(true);
              postShare({chart:data}).then(result=>{
                setSharing(false);
                if("status" in result && result.status === 1)
                  setUrlSharing(result.payload.url);
              }).catch(()=>{
                setSharing(false);
                setOpenSharing(false);
              })
            }}
          />
        )}
      </Paper>
    </>
  )
};

PainelCard.propTypes = {
  variant: PropTypes.oneOf(['ranking', 'chart', 'mini']),
  title: PropTypes.string,
  share: PropTypes.bool,
  headerProps: PropTypes.shape({
    bordered: PropTypes.bool,
    enabled: PropTypes.bool,
    pagination: PropTypes.bool
  }),
  footerProps: PropTypes.shape({
    bordered: PropTypes.bool,
    enabled: PropTypes.bool,
    comparisonEnabled: PropTypes.bool
  }),
  height: PropTypes.number
};

PainelCard.defaultProps = {
  title: '',
  share: true,
  headerProps: {
    bordered: false,
    enabled: true,
    pagination: false
  },
  footerProps: {
    bordered: false,
    enabled: true
  },
  height: GYAAN_CHART_HEIGHT
};

export * from './PainelCardContent';
export default withWidth()(PainelCard);