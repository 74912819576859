import React, {useContext} from 'react';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';
import useStyles from "./style";

import ViewWrapper from '../../components/ViewWrapper';
import SummaryComprasDiarias from "../../components/SummaryComprasDiarias";
import SummaryComprasFornecedor from "../../components/SummaryComprasFornecedor";
import SummaryComprasGrupos from "../../components/SummaryComprasGrupos";
import RankingGrupos from "../../components/RankingGrupos";
import ChartItensComprados from "../../components/ChartItensComprados";
import ChartItensCotados from "../../components/ChartItensCotados";
import ChartVolumeCompras from "../../components/ChartVolumeCompras";
import ChartEvolucaoCompras from "../../components/ChartEvolucaoCompras";
import RankingMarcas from "../../components/RankingMarcas";
import ChartItensNaoCotados from "../../components/ChartItensNaoCotados";
import ChartComprasGrupos from "../../components/ChartComprasGrupos";
import ChartComprasFornecedores from "../../components/ChartComprasFornecedores";
import ChartComprasDiarias from "../../components/ChartComprasDiarias";
import ChartComprasDiariasPorEmpresa from "../../components/ChartComprasDiariasPorEmpresa";
import ChartVolumeComprasDrillFornecedores from "../../components/ChartVolumeComprasDrillFornecedores";
import ChartVolumeComprasDrillGrupos from "../../components/ChartVolumeComprasDrillGrupos";
import ChartCompradores from "../../components/ChartCompradores";
import {useFavorite, useUser} from "../../utils/utils";
import {
  GYAAN_QUERY_COMPRAS_DIARIAS,
  GYAAN_QUERY_COMPRAS_DIARIAS_EMPRESAS,
  GYAAN_QUERY_COMPRAS_FORNECEDOR,
  GYAAN_QUERY_COMPRAS_GRUPO,
  GYAAN_QUERY_COMPRAS_MES_USUARIO,
  GYAAN_QUERY_COMPRAS_USUARIO,
  GYAAN_QUERY_ITENS_COMPRADOS_USUARIO,
  GYAAN_QUERY_ITENS_COTADOS_USUARIO,
  GYAAN_QUERY_ITENS_NAO_COTADOS,
  GYAAN_QUERY_RANKING_GRUPOS,
  GYAAN_QUERY_RANKING_MARCAS,
  GYAAN_QUERY_RESUMO_COMPRAS,
  GYAAN_QUERY_RESUMO_FORNECEDORES,
  GYAAN_QUERY_RESUMO_GRUPO,
  GYAAN_QUERY_DRILL_FORNECEDORES,
  GYAAN_QUERY_DRILL_GRUPOS,
  GYAAN_QUERY_COMPRADORES
} from "../../utils/constants";
import UserContext from "../../UserContext";

const getSumForLine = (queries, line) => {
  return queries.reduce((result, each) => {
    if(each.nr_linha !==null && each.nr_linha.toString() === line.toString() && each.cs_favorito.toString() === '1')
      result += each.nr_peso_grafico;
    return result;
  }, 0);
};

const getWeight = (queries, id) => {
  return queries.filter(each => each.id_sql.toString() === id.toString())[0].nr_peso_grafico;
};

const getWidth = queries => (id, line) => {
  return (getWeight(queries, id)/getSumForLine(queries, line)) * 12;
};

export default ({history, match, usuario, onLogout}) => {
  const
    LINE_ONE = 1,
    LINE_TWO = 2,
    LINE_THREE = 3,
    LINE_FOUR = 4,
    LINE_SIX = 6
  ;
  const classes = useStyles();
  let {user} = useContext(UserContext);
  if(!user)
    user = useUser();
  const has_most_one_user = user.tl_usuarios_compradores;
  const getColumn = getWidth(user.queries||[]);

  const spacing = 2;
  const hasFistContainer = (useFavorite(GYAAN_QUERY_RESUMO_COMPRAS) || useFavorite(GYAAN_QUERY_RESUMO_FORNECEDORES) || useFavorite(GYAAN_QUERY_RESUMO_GRUPO));

  return (
    <>
      <ViewWrapper history={history} match={match} usuario={usuario} onLogout={onLogout}>
        {(useFavorite(GYAAN_QUERY_RESUMO_COMPRAS) || useFavorite(GYAAN_QUERY_RESUMO_FORNECEDORES) || useFavorite(GYAAN_QUERY_RESUMO_GRUPO)) && (
          <Grid container spacing={spacing} className={classes.first}>
            {useFavorite(GYAAN_QUERY_RESUMO_COMPRAS) && (
              <Grid item
                    lg={getColumn(GYAAN_QUERY_RESUMO_COMPRAS, LINE_ONE)}
                    md={getColumn(GYAAN_QUERY_RESUMO_COMPRAS, LINE_ONE)}
                    sm={12} xs={12}>
                <SummaryComprasDiarias />
              </Grid>
            )}

            {useFavorite(GYAAN_QUERY_RESUMO_FORNECEDORES) && (
              <Grid item
                    lg={getColumn(GYAAN_QUERY_RESUMO_FORNECEDORES, LINE_ONE)}
                    md={getColumn(GYAAN_QUERY_RESUMO_FORNECEDORES, LINE_ONE)}
                    sm={12} xs={12}>
                <SummaryComprasFornecedor />
              </Grid>
            )}

            {useFavorite(GYAAN_QUERY_RESUMO_GRUPO) && (
              <Grid item
                    lg={getColumn(GYAAN_QUERY_RESUMO_GRUPO, LINE_ONE)}
                    md={getColumn(GYAAN_QUERY_RESUMO_GRUPO, LINE_ONE)}
                    sm={12} xs={12}>
                <SummaryComprasGrupos />
              </Grid>
            )}
          </Grid>
        )}
        <Grid container spacing={spacing} className={clsx(classes.grid, !hasFistContainer && classes.gutterTop)}>
          {useFavorite(GYAAN_QUERY_RANKING_GRUPOS) && (
            <Grid item
                  lg={getColumn(GYAAN_QUERY_RANKING_GRUPOS, LINE_TWO)}
                  md={getColumn(GYAAN_QUERY_RANKING_GRUPOS, LINE_TWO)}
                  sm={12} xs={12}>
              <RankingGrupos/>
            </Grid>
          )}

          { (has_most_one_user !== null && has_most_one_user > 1 && useFavorite(GYAAN_QUERY_ITENS_COMPRADOS_USUARIO)) && (
            <Grid item
                  lg={getColumn(GYAAN_QUERY_ITENS_COMPRADOS_USUARIO, LINE_TWO)}
                  md={getColumn(GYAAN_QUERY_ITENS_COMPRADOS_USUARIO, LINE_TWO)}
                  sm={12} xs={12}>
              <ChartItensComprados />
            </Grid>
          )}

          {( (has_most_one_user === null || has_most_one_user <= 1) && useFavorite(GYAAN_QUERY_ITENS_NAO_COTADOS)) && (
            <Grid
              item
              lg={getColumn(GYAAN_QUERY_ITENS_NAO_COTADOS, LINE_TWO)}
              md={getColumn(GYAAN_QUERY_ITENS_NAO_COTADOS, LINE_TWO)}
              sm={12} xs={12}>
              <ChartItensNaoCotados />
            </Grid>
          )}

        </Grid>

        {(has_most_one_user !== null && has_most_one_user > 1) && (
          <>

            <Grid container spacing={spacing} className={classes.grid}>

              {useFavorite(GYAAN_QUERY_ITENS_COTADOS_USUARIO) && (
                <Grid item
                      lg={getColumn(GYAAN_QUERY_ITENS_COTADOS_USUARIO, LINE_THREE)}
                      md={getColumn(GYAAN_QUERY_ITENS_NAO_COTADOS, LINE_THREE)}
                      sm={12} xs={12}>
                  <ChartItensCotados />
                </Grid>
              )}

              {useFavorite(GYAAN_QUERY_COMPRAS_USUARIO) && (
                <Grid item
                      lg={getColumn(GYAAN_QUERY_COMPRAS_USUARIO, LINE_THREE)}
                      md={getColumn(GYAAN_QUERY_COMPRAS_USUARIO, LINE_THREE)}
                      sm={12} xs={12}>
                  <ChartVolumeCompras />
                </Grid>
              )}

            </Grid>

            <Grid container spacing={spacing} className={classes.grid}>
              {useFavorite(GYAAN_QUERY_COMPRAS_MES_USUARIO) && (
                <Grid item
                      lg={getColumn(GYAAN_QUERY_COMPRAS_MES_USUARIO, LINE_FOUR)}
                      md={getColumn(GYAAN_QUERY_COMPRAS_MES_USUARIO, LINE_FOUR)}
                      sm={12} xs={12}>
                  <ChartEvolucaoCompras />
                </Grid>
              )}
              {useFavorite(GYAAN_QUERY_RANKING_MARCAS) && (
                <Grid item
                      lg={getColumn(GYAAN_QUERY_RANKING_MARCAS, LINE_FOUR)}
                      md={getColumn(GYAAN_QUERY_RANKING_MARCAS, LINE_FOUR)}
                      sm={12} xs={12}>
                  <RankingMarcas/>
                </Grid>
              )}
            </Grid>

            <Grid container spacing={spacing} className={classes.grid}>
              {useFavorite(GYAAN_QUERY_ITENS_NAO_COTADOS) && (
                <Grid item lg={12} md={8} sm={12} xs={12}>
                  <ChartItensNaoCotados />
                </Grid>
              )}
            </Grid>
          </>
        )}

        <Grid container spacing={spacing} className={classes.grid}>
          {useFavorite(GYAAN_QUERY_COMPRAS_GRUPO) && (
            <Grid item
                  lg={getColumn(GYAAN_QUERY_COMPRAS_GRUPO, LINE_SIX)}
                  md={getColumn(GYAAN_QUERY_COMPRAS_GRUPO, LINE_SIX)}
                  sm={12} xs={12}>
              <ChartComprasGrupos />
            </Grid>
          )}

          {useFavorite(GYAAN_QUERY_COMPRAS_FORNECEDOR) && (
            <Grid item
                  lg={getColumn(GYAAN_QUERY_COMPRAS_FORNECEDOR, LINE_SIX)}
                  md={getColumn(GYAAN_QUERY_COMPRAS_FORNECEDOR, LINE_SIX)}
                  sm={12} xs={12}>
              <ChartComprasFornecedores />
            </Grid>
          )}
        </Grid>

        <Grid container spacing={spacing} className={classes.grid}>
          {useFavorite(GYAAN_QUERY_COMPRAS_DIARIAS) && (
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <ChartComprasDiarias />
            </Grid>
          )}

          {useFavorite(GYAAN_QUERY_COMPRAS_DIARIAS_EMPRESAS) && (
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <ChartComprasDiariasPorEmpresa />
            </Grid>
          )}

          {useFavorite(GYAAN_QUERY_DRILL_FORNECEDORES) && (
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <ChartVolumeComprasDrillFornecedores />
            </Grid>
          )}

          {useFavorite(GYAAN_QUERY_DRILL_GRUPOS) && (
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <ChartVolumeComprasDrillGrupos />
            </Grid>
          )}

          {(useFavorite(GYAAN_QUERY_COMPRADORES) && has_most_one_user !== null && has_most_one_user > 1) && (
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <ChartCompradores />
            </Grid>
          )}

        </Grid>
      </ViewWrapper>
    </>
  )
};