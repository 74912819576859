import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  IconButton,
  Typography,
  Menu,
  MenuItem,
  MobileStepper,
  ListItemIcon, Divider, useTheme, makeStyles
} from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight, Print } from '@material-ui/icons';
import MenuIcon from '@material-ui/icons/Menu';
import useStyles from '../style.js';

const privateStyles = makeStyles({
  button: {
    minWidth: 35
  },
  sizeSmall: {
    padding: 6
  },
  stepper: {
    backgroundColor: 'transparent',
    padding: 0
  }
});

const Container = ({classes, children, bordered}) =>
  <Typography component="div" className={clsx(classes['painel-card-header'], bordered && classes['card-ranking-header__bordered'])}>
    {children}
  </Typography>
;

const Title = ({classes, title}) =>
  <div className={classes['card-ranking-header__title']}>
    {title}
  </div>
;
/*
const PaginationNextButton = ({classes, onNext, page, maxPages}) => {
  const theme = useTheme();
  const privateClasses = privateStyles();
  return (
    <IconButton
      className={privateClasses.sizeSmall}
      onClick={onNext}
      disabled={page === maxPages - 1}
    >
      {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
    </IconButton>
  );
};
*/
const PainelCardHeader = ({title, chartRef, bordered, anchorMenu, onCloseMenu, onOpenMenu, onDownload, onNext, onBack, pagination, page, maxPages}) => {
  const classes = useStyles();
  const theme = useTheme();
  const privateClasses = privateStyles();
  return (
    <Container bordered={bordered} classes={classes}>
      <Title title={title} classes={classes} />
      {pagination && (
        <MobileStepper
          className={privateClasses.stepper}
          steps={maxPages}
          position="static"
          variant="text"
          activeStep={page}
          nextButton={
            <IconButton
              className={privateClasses.sizeSmall}
              onClick={onNext}
              disabled={page === maxPages - 1}
            >
              {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
          }
          backButton={
            <IconButton
              className={privateClasses.sizeSmall}
              onClick={onBack}
              disabled={page === 0}
            >
              {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
          }
        />
      )}
      <IconButton aria-controls="actions-menu" className={classes.action} onClick={onOpenMenu}>
        <MenuIcon/>
      </IconButton>
      <Menu
        id="actions-menu"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={onCloseMenu}
        keepMounted
      >
        <MenuItem style={{minHeight: 0}} onClick={() => onDownload(chartRef, 'png')}>
          <ListItemIcon>
            <Print/>
          </ListItemIcon>
          <Typography>Download Imagem PNG</Typography>
        </MenuItem>
        <MenuItem style={{minHeight: 0}} onClick={() => onDownload(chartRef, 'pdf')}>
          <ListItemIcon>
            <Print/>
          </ListItemIcon>
          <Typography>Download Imagem PDF</Typography>
        </MenuItem>
        <Divider />
        <MenuItem style={{minHeight: 0}} onClick={() => onDownload(chartRef, 'xls')}>
          <ListItemIcon>
            <Print/>
          </ListItemIcon>
          <Typography>Download XLS</Typography>
        </MenuItem>
        <MenuItem style={{minHeight: 0}} onClick={() => onDownload(chartRef, 'csv')}>
          <ListItemIcon>
            <Print/>
          </ListItemIcon>
          <Typography>Download CSV</Typography>
        </MenuItem>
      </Menu>
    </Container>
  )
};

PainelCardHeader.propTypes = {
  title: PropTypes.string,
  favorite: PropTypes.bool,
  bordered: PropTypes.bool,
  pagination: PropTypes.bool
};

export default PainelCardHeader;