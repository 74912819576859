import React, { useState, useEffect } from 'react';
import Sidebar from '../../components/Sidebar';
import TopBar from '../TopBar';
import MobileBar from '../MobileBar';
import NotificationsPopover from '../NotificationsPopover';
import UserOptionsPopover from '../UserOptionsPopover';
import useStyles from "./style";
import { Hidden } from '@material-ui/core';
import {
  GYAAN_TAB_PAINEL,
  GYAAN_TAB_NOTIFICACOES,
} from '../../utils/constants';
import { getTextFromTab } from '../../utils/utils';

const Main = ({children}) => {
  const classes = useStyles();
  return (
    <main className={classes.main}>
      {children}
    </main>
  )
};

function ViewWrapper({match, children, usuario, onLogout, history}) {
  const [tab, setTab] = useState(GYAAN_TAB_PAINEL);
  const [ , setSubTab] = useState(0);
  const [anchorPopover, setAnchorPopover] = useState(null);
  const [notificationsPopover, setNotificationsPopover] = useState(null);
  const [hasNotifications, setHasNotifications] = useState(true);
  const [path, setPath] = useState({
    url: '',
    breadcrumbs: []
  });

  useEffect(() => {
    const { url } = match,
      breadcrumbs = url.split("/").splice(1, url.split("/").length - 1);

    setPath({
      url,
      breadcrumbs
    });

    setTab(getTabOnComponentDidMount(breadcrumbs));
    setSubTab(getSubTabOnComponentDidMount(breadcrumbs));
  }, [match]);

  const handleOpenPopover = e =>
    setAnchorPopover(e.currentTarget);

  const handleClosePopover = () =>
    setAnchorPopover(null);

  const handleOpenNotificationsPopover = e => {
    setHasNotifications(false);
    setNotificationsPopover(e.currentTarget);
  };

  const handleCloseNotificationsPopover = () =>
    setNotificationsPopover(null);

  const getTabOnComponentDidMount = breadcrumbs =>
    breadcrumbs[0];

  const getSubTabOnComponentDidMount = breadcrumbs =>
    breadcrumbs[1];

  const handleChangeTab = (tab, to) => {
    setTab(tab);
    if (tab !== GYAAN_TAB_NOTIFICACOES)
      history.push(to);
  };

  const handleChangeSubTab = subtab =>
    setSubTab(subtab);

  const renderDesktopOnly = () => (
    <Hidden only={['xs', 'sm']}>
      <Sidebar
        tab={tab}
        usuario={usuario}
        onChangeTab={handleChangeTab}
        onChangeSubTab={handleChangeSubTab}
        onLogout={onLogout}
      />
      <TopBar
        usuario={usuario}
        breadcrumbs={path.breadcrumbs}
        onOpenNotificationsPopover={handleOpenNotificationsPopover}
        handleOpenUserPopover={handleOpenPopover}
        anchorUser={anchorPopover}
        anchorNotifications={notificationsPopover}
        hasNotifications={hasNotifications}
        onLogout={onLogout}
      />
    </Hidden>
  );

  const renderMobileOnly = () => (
    <Hidden only={['md', 'lg', 'xl']}>
      <MobileBar
        tab={tab}
        title={getTextFromTab(tab)}
        usuario={usuario}
        hasNotifications={hasNotifications}
        onChangeTab={handleChangeTab}
        onOpenNotificationsPopover={handleOpenNotificationsPopover}
        onLogout={onLogout}
      />
    </Hidden>
  );

  const renderMain = () => (
    <Main children={children}/>
  );

  return (
    <>
      {/*Popovers*/}
      <UserOptionsPopover
        anchorEl={anchorPopover}
        usuario={usuario}
        onClose={handleClosePopover}
        onLogout={onLogout}
      />
      <NotificationsPopover
        anchorEl={notificationsPopover}
        usuario={usuario}
        onClose={handleCloseNotificationsPopover}
        onLogout={onLogout}
      />

      {/*Renders*/}
      {renderDesktopOnly()}
      {renderMobileOnly()}
      {renderMain()}
    </>
  )
}

export default ViewWrapper;