import {withStyles, TextField as TF} from "@material-ui/core";
import {
  GYAAN_COLOR_ADORNMENT,
  GYAAN_COLOR_ADORNMENT_DARK,
  GYAAN_COLOR_PRIMARY_LIGHT
} from "../../utils/constants";

const TextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: GYAAN_COLOR_PRIMARY_LIGHT,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: GYAAN_COLOR_ADORNMENT,
      },
      '&:hover fieldset': {
        borderColor: GYAAN_COLOR_ADORNMENT_DARK,
      },
      '&.Mui-focused fieldset': {
        borderColor: GYAAN_COLOR_PRIMARY_LIGHT,
      },
    },
  }
})(TF);

export default TextField;