import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles( theme => ({
  widget: props => ({
    height: 10,
    width: props.inline ? '100%' : '100%',
    borderRadius: 30,
    float: 'right'
  }),
  root: props => ({
    display: 'flex',
    flexDirection: props.inline ? 'row' : 'column'
  }),
  percent: props => ({
    fontSize: props.inline ? 14 : 16,
    width: props.inline ? '35%' : null,
    marginRight: 15
  }),
  widgetContainer: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1
  },
  tooltip: props => ({
    backgroundColor: props.color,
    color: props.textColor,
    boxShadow: theme.shadows[1],
    fontSize: 11,
  })
}));

export default useStyles;