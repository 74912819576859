import React from 'react';
import {
  Typography,
  List,
  Popover,
} from '@material-ui/core';
import ListItemLink from '../ListItemLink/index';
import {
  ExitToApp
} from '@material-ui/icons';
import deepOrangeBg from '../../images/head_gb_sm_deep_orange.jpg';
import useStyles from '../Sidebar/style';

export default ({anchorEl, usuario, onClose, onLogout}) => {
  const classes = useStyles();

  const anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'center',
  };
  const transformOrigin = {
    vertical: 'top',
    horizontal: 'center'
  };
  const options = [
    {label: 'Sair', icon: <ExitToApp style={{transform: 'rotate(180deg)'}}/>, to: onLogout}
  ];

  return (
    <Popover
      id="popover"
      classes={{
        paper: classes.popoverPaper
      }}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      onClose={onClose}
      disableRestoreFocus
    >
      <div style={{backgroundImage: `url(${deepOrangeBg})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', height: '40%', position: 'relative'}}>
        <div style={{position: 'absolute', bottom: -45/2, paddingLeft: 10 }}>
          <div style={{width: '100%', display: 'flex'}}>
            <div style={{width: 45, height: 45, borderRadius: '50%'}}>
              <img src={usuario.nm_arquivo} alt="Lo" style={{width: '100%', borderRadius: '50%'}} />
            </div>
            <div style={{marginLeft: 10}}>
              <Typography style={{color: 'white'}}>{usuario.nm_usuario}</Typography>
              <Typography style={{color: '#666'}}>{usuario.nm_fantasia}</Typography>
            </div>
          </div>
        </div>
      </div>
      <div style={{marginTop: 40}}>
        <List>
          {options.length > 0 && options.map(option => (
            <ListItemLink
              key={option.label}
              to={option.to}
              icon={option.icon}
              primary={option.label}
              button
              style={{paddingTop: 0, paddingBottom: 0}}
            />
          ))}
        </List>
      </div>
    </Popover>
  )
};