import {makeStyles} from "@material-ui/core";
import {blue, grey} from "@material-ui/core/colors";

const useStyles = makeStyles({
  popoverPaper: {
    width: 300,
    height: 'auto',
    maxHeight: 350,
    overflow: 'hidden',
    overflowY: 'auto'
    // backgroundColor: grey[200]
  },
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  title: {
    padding: '10px 15px', borderBottom: `1px solid ${grey[300]}`
  },
  textPrimary: {
    fontSize: 14
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0
  },
  item: {
    '&:hover': {
      backgroundColor: grey[200]
    }
  },
  new: {
    backgroundColor: blue[100]
  }
});

export default useStyles;