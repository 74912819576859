import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles({
  root:{
    paddingRight: 15,
    paddingLeft: 15,
    paddingTop: 10
  }
});

export default useStyles;