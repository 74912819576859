import {
  GYAAN_STORAGE_ITEM_TOKEN
} from './constants';
import {setSessionPeriod} from "./utils";

export const

  host = process.env.NODE_ENV === 'production' ? 'https://www.gyaan.com.br/api' : 'http://127.0.0.1:5000/api',

  // DESCOMENTAR ESSA LINHA PARA GERAR VERSAO HOMOLOGACAO
  // host = process.env.NODE_ENV === 'production' ? 'https://192.168.15.12:8080/api' : 'http://192.168.15.12:8080/api',

  mode = process.env.NODE_ENV === 'production' ? undefined : 'cors',
  getRequestHeaders = headers => ({
    'Accept': 'application/json',
    'Authorization': `Bearer ${sessionStorage.getItem(GYAAN_STORAGE_ITEM_TOKEN)}`,
    'Content-Type': 'application/json',
    ...headers
  }),
  getDataFromQuery = async ({id_query, id_period, dt_start, dt_end, comparison=false}) =>
    fetch(
      `${host}/queries/${id_query}/${id_period}/${dt_start}/${dt_end}${comparison?'?cptv':''}`,
      {method: 'GET', mode, headers: getRequestHeaders()})
      .then(response => {
        setSessionPeriod(id_query, id_period);
        return response.json();
      })
      .catch(err => console.error(err))
  ,
  getCachedDataFromQuery = async ({id_query, id_period, dt_start, dt_end}) =>
    caches.match(`${host}/queries/${id_query}/${id_period}/${dt_start}/${dt_end}`)
      .then(response => {
        if(!response)
          throw new Error('Não há dados no cache ou resultado não confiável');
        return response.json();
      })
  ,
  getAllFornecedores = async () =>
    fetch(`${host}/fornecedores`, {method: 'GET', mode, headers: getRequestHeaders()})
      .then(response => response.json())
      .catch(err => console.error(err))
  ,
  getAllGrupos = async () =>
    fetch(`${host}/grupos`, {method: 'GET', mode, headers: getRequestHeaders()})
      .then(response => response.json())
      .catch(err => console.error(err))
  ,
  registerDevice = async ({token, token_old}) =>
    fetch(
      `${host}/devices`,
      {method: 'POST', mode, headers: getRequestHeaders(), body:JSON.stringify({token, token_old})})
      .then(response => response.json())
      .catch(err => console.error(err))
  ,
  postLogin = usuario =>
    fetch(
      `${host}/login`,
      {method:'POST', body: JSON.stringify(usuario), mode, headers: getRequestHeaders()})
      .then(response => response.json())
      .catch(err => console.error(err))
  ,
  putFavorite = (id_query, is_favorite=false) =>
    fetch(
      `${host}/favoritos/${id_query}`,
      {method:'PUT', body: JSON.stringify({cs_favorito:is_favorite?'1':'0'}), mode, headers: getRequestHeaders()})
      .then(response => response.json())
      .catch(err => console.error(err))
  ,
  postShare = chart =>
    fetch( `${host}/share`, {method:'POST', body:JSON.stringify(chart), mode, headers:getRequestHeaders()})
      .then(response => response.json())
      .catch(err => console.error(err))
;