import {makeStyles} from "@material-ui/core";
import {GYAAN_BAR_WIDTH, GYAAN_SIDEBAR_WIDTH} from "../../utils/constants";

const useStyles = makeStyles( theme => ({
  main: {
    paddingTop: 30,
    transition: theme.transitions.create(['padding-left', 'padding-right'], {
      duration: theme.transitions.duration.leavingScreen
    }),
    [theme.breakpoints.up('md')]: {
      paddingLeft: GYAAN_SIDEBAR_WIDTH + 30,
      paddingRight: 30,
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 16,
      paddingRight: 16,
      paddingBottom: 20,
      paddingTop: GYAAN_BAR_WIDTH + 10,
      WebkitOverflowScrolling: 'touch',
      maxHeight: '100vh'
    },
  },
  breadcrumbs: {
    flexGrow: 1
  },
  containerFluid: {
    flexGrow: 1
  },
  appBar: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    width: `calc(100% - ${GYAAN_SIDEBAR_WIDTH}px)`
  }
}));

export default useStyles;