import {List} from "@material-ui/core";
import ListItemLink from "../../ListItemLink";
import React from "react";
import useStyles from "./style";

const DrawerList = ({tab, items=[], onToggle}) => {
  const classes = useStyles();
  return (
    <div
      className={classes.list}
      role="presentation"
      onClick={onToggle(false)}
      onKeyDown={onToggle(false)}
    >
      <List>
        {items.length > 0 && items.map(item => (
          <ListItemLink
            key={item.tab}
            to={item.to}
            icon={item.icon}
            primary={item.title}
            selected={tab === item.tab}
            className={classes.listItem}
            classes={{selected: classes.listItemSelected}}
            onClick={() => item.onClick(item.tab)}
            button
          />
        ))}
      </List>
    </div>
  );
};

export default DrawerList;