import React from 'react';
import {
  GYAAN_QUERY_COMPRAS_GRUPO,
} from '../../utils/constants';
import Chart from '../SimpleChart';
import Loading from '../../components/Loading';
import PainelCard from '../PainelCard';

const tooltip = (name, color, key, y) =>
  `<b>${name}</b><br /><span style="color:${color}">${key}</span>:<b>${y}</b>`
;

const ChartComprasGrupos = () => {
  return (
    <>
      <PainelCard
        variant="chart"
        id_query={GYAAN_QUERY_COMPRAS_GRUPO}
        headerProps={{enabled: false}}
        render={({data, loading}) => {
          return (
            <>
              {loading ? (
                <Loading/>
              ) : (
                <Chart
                  options={data}
                  tooltipFormatter={function(){return tooltip(this.series.name, this.color, this.key, this.y)}}
                />
              )}
            </>
          )
        }}
      />
    </>
  );
};

export default ChartComprasGrupos;
