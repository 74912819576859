import { makeStyles } from '@material-ui/core/styles/index';
import { grey, yellow } from '@material-ui/core/colors';
import {
  GYAAN_PAINEL_CARD_MINI_HEIGHT,
  GYAAN_PAINEL_CARD_RANKING_HEIGHT,
  GYAAN_PAINEL_CARD_CHART_HEIGHT,
} from '../../utils/constants';

const useStyles = makeStyles({
  'card-chart-paper': {
    height: GYAAN_PAINEL_CARD_CHART_HEIGHT,
    maxHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
  },
  'painel-card-header': {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: 10,
    paddingLeft: 15,
    paddingRight: 15,
    paddingBottom: 10,
  },
  'painel-card-header__ranking-bordered': {
    borderBottom: `1px solid ${grey[300]}`
  },
  'painel-card-header__title': {
    flexGrow: 1
  },
  'painel-card-content__ranking': {
    paddingLeft: 10,
    paddingRight: 10,
    position: 'relative',
    height: 'calc(100% - 100px)',
    maxHeight: 'calc(100% - 100px)',
    overflow: 'auto'
  },
  'painel-card-footer': {
    width: '100%',
    '& > div': {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexDirection: 'row',
      paddingLeft: 15,
      paddingRight: 15
    }
    // paddingTop: 10,
  },
  'card-ranking-paper': {
    minHeight: GYAAN_PAINEL_CARD_RANKING_HEIGHT,
    height: GYAAN_PAINEL_CARD_RANKING_HEIGHT,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
  },
  'card-ranking-header': {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: 10,
    paddingLeft: 15,
    paddingRight: 15,
    paddingBottom: 10,
    // height: '10%',
  },
  'card-ranking-header__title': {
    flexGrow: 1
  },
  action: {
    padding: 6
  },
  actionIconActive: {
    color: yellow[700]
  },
  'card-ranking-content': {
    flexGrow: 1,
    maxHeight: 'calc(100% - 50px)',
    overflow: 'hidden',
    overflowY: 'auto'
  },
  'card-ranking-header__bordered': {
    borderBottom: `1px solid ${grey[300]}`
  },
  loading: {
    border: '1px solid black',

  },
  'card-ranking-primary': {
    height: 20
  },
  'card-ranking-secondary': {
    height: 20,
    flexBasis: `20%`,
    maxWidth: `20%`,
    width: `20%`
  },
  'card-ranking-footer': {
    width: '100%',
    '& > div': {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexDirection: 'row',
      paddingLeft: 15,
      paddingRight: 15
    }
    // paddingTop: 10,
  },
  'card-ranking-footer__gutter': {
    marginBottom: 8
  },
  'card-mini-paper': {
    minHeight: GYAAN_PAINEL_CARD_MINI_HEIGHT,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
  },
  'card-mini-header': {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: 10,
    paddingLeft: 15,
    paddingRight: 15
  },
  'card-mini-header__title': {
    flexGrow: 1
  },
  'card-mini-value': {
    fontSize: '2rem'
  },
  'card-mini-action': {
    padding: 6
  },
  'card-mini-action-icon__favorite': {
    color: yellow[700]
  },
  'card-mini-content': {
    width: '100%',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    position: 'relative',
  },
  bgImageContainer: {
    width: '100%',
    height: '100%',
    zIndex: 1,
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bgImage: {
    opacity: 0.1,
    width: '40%',
    height: '150%'
  },
  'card-mini-prefix': {
    fontSize: '1rem',
    marginTop: 4
  },
  'card-mini-prefix__lighter': {
    color: grey[600]
  },
  'card-mini-primary': {
    // paddingLeft: 15,
    paddingRight: 30,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '1.5rem',
  },
  'card-mini-loading__primary': {
    width: '80%',
    minHeight: 50,
    marginBottom: 10
  },
  'card-mini-loading__secondary': {
    width: '45%',
    minHeight: 25
  },
  'card-chart-content': {
    width: '100%',
    flexGrow: 1,
    // height: GYAAN_CHART_HEIGHT,
  },
  secondary: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  'mini-container': {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  'mini-content': {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  'mini-content__primary': {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 15
  },
  'mini-content__secondary': {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    paddingLeft: 15,
    paddingRight: 15
  },
  root: {
    animationDuration: '650ms',
    color: '#757575',
  }
});

export default useStyles;