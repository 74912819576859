import React, {useState} from "react";
import UserContext from "./UserContext";
import {useUser} from "./utils/utils";

const UserProvider = ({children}) => {

  const changeUser = user => {
    setUser({user:user, changeUser});
  };

  const [user, setUser] = useState({user:useUser(), changeUser});

  return (
    <UserContext.Provider value={user}>
      {children}
    </UserContext.Provider>
  )
};

export default UserProvider;