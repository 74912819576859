import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {IconButton} from '@material-ui/core';
import Period from '../../Period/index';
import { Star, StarBorder, ShowChart, ShareOutlined } from '@material-ui/icons';
import useStyles from '../style.js';

const PainelCardFooter = ({variant, anchorEl, period, favorite, comparison, onOpenPeriod, onClosePeriod, onSelectPeriod, onFavorite, comparisonEnabled = false, shareEnabled=false, onComparison=()=>{console.warn('Not implemented')}, onShare}) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes['painel-card-footer'])}>
      <div>
        <Period
          anchorEl={anchorEl}
          period={period}
          onOpen={onOpenPeriod}
          onClose={onClosePeriod}
          onSelect={onSelectPeriod}
        />
        <div style={{flexGrow: 1, display: 'flex', justifyContent: 'flex-end'}}>
          {comparisonEnabled && (
            <IconButton className={classes.action} onClick={onComparison}>
              <ShowChart className={comparison?classes.actionIconActive:undefined}/>
            </IconButton>
          )}

          { (shareEnabled && "share" in navigator) && (
            <IconButton className={classes.action} onClick={onShare}>
              <ShareOutlined />
            </IconButton>
          )}

          <IconButton className={classes.action} onClick={e => onFavorite(e)} data-favorite={favorite}>
            {favorite?(<Star className={classes.actionIconActive}/>):(<StarBorder/>)}
          </IconButton>
        </div>
      </div>
    </div>
  )
};

PainelCardFooter.propTypes = {
  variant: PropTypes.string,
  anchorEl: PropTypes.any,
  comparison: PropTypes.bool,
  comparisonEnabled: PropTypes.bool,
  shareEnabled: PropTypes.bool,
  period: PropTypes.string.isRequired,
  onOpenPeriod: PropTypes.func.isRequired,
  onSelectPeriod: PropTypes.func.isRequired,
  onFavorite: PropTypes.func,
  onShare: PropTypes.func
};

export default PainelCardFooter;