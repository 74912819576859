import React from 'react';
import clsx from 'clsx';
import useStyles from '../style';

import SidebarActionItem from '../SidebarActionItem';

export default ({children, tab, gutterBottom = false, items = []}) => {
  const classes = useStyles();
  return (
    <div
      className={clsx(
        classes.sidebarAction,
        {
          [classes.gutterBottom]: gutterBottom,
          [classes.gutterTop]: !gutterBottom
        }
      )}
    >
      {!children && items.length > 0 && items.map(item => (
        <SidebarActionItem
          key={item.tab}
          title={item.title}
          active={item.tab === tab}
          value={item.tab}
          icon={item.icon}
          onClick={item.onClick}
          to={item.to}
        />
      ))}
      {children && children}
    </div>
  )
};