import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {Typography} from '@material-ui/core';

const MiniContentPrimary = ({loading, primary, currency, classes}) =>
  !loading &&
    <div className={classes['mini-content']}>
      <div className={classes['mini-content__primary']}>
        {currency && <Typography className={clsx(classes['card-mini-prefix'], classes['card-mini-prefix__lighter'])}>{currency}</Typography>}
        <Typography className={classes['card-mini-primary']}>{primary}</Typography>
      </div>
    </div>
  ;

MiniContentPrimary.propTypes = {
  loading: PropTypes.bool,
  primary: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  currency: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export { MiniContentPrimary };
export default MiniContentPrimary;