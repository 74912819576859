import useStyles from "./style";
import React from "react";
import {List, ListItem, ListItemSecondaryAction, ListItemText} from "@material-ui/core";
import clsx from "clsx";

const RankingLoading = ({loading}) => {
  const classes = useStyles();
  return (
    <>
      {loading && (
        <List component="ul">
          {['60%','50%','65%','25%','45%','80%','75%','48%', '88%'].map(item => (
            <ListItem key={item} component="li">
              <ListItemText
                className={clsx(classes['card-ranking-primary'], 'animation-loading')}
                style={{
                  flexBasis: item,
                  maxWidth: item
                }}
              />
              <ListItemSecondaryAction className={clsx(classes['card-ranking-secondary'],'animation-loading')} />
            </ListItem>
          ))}
        </List>
      )}
    </>
  )
};

export default RankingLoading;