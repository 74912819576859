import {makeStyles} from "@material-ui/core";
import {
  GYAAN_COLOR_PRIMARY,
  GYAAN_COLOR_TEXT_PRIMARY,
} from '../../utils/constants';

const useStyles = makeStyles( theme => ({
  root: {
    backgroundColor: 'transparent',
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 100
  },
  backPhoto: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0
  },
  login: {
    backgroundColor: 'rgba(0,0,0,.3)',
    borderRadius: 25,
    boxShadow: '2px 5px 20px rgba(0,0,0,1)',
    width: 350,
    height: 500,
    position: 'relative'
  },
  welcome: {
    position: 'absolute',
    top: -75,
    width: '100%',
    height: 75
  },
  innerWelcome: {
    fontSize: '2rem',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    flexWrap: 'nowrap',
    whiteSpace: 'nowrap'
  },
  userImageContainer: {
    height: '40%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  userImage: {
    width: '80%',
    // height: 150,
    // borderRadius: '50%',
    // boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
  },
  image: {
    width: '100%',
    // borderRadius: '50%'
  },
  input: {
    width: '90%',
    padding: '5px 10px',
    backgroundColor: 'transparent',
    border: 'none'
  },
  inputContainer: {
    width: '80%',
    backgroundColor: 'white',
    border: '1px solid #eee',
    borderRadius: 4,
    boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
    position: 'relative',
    display: 'flex',
    alignItems: 'center'
  },
  button: {
    backgroundColor: GYAAN_COLOR_PRIMARY,
    color: GYAAN_COLOR_TEXT_PRIMARY,
    height: 50,
    transition: theme.transitions.create('all', {
      duration: '.15s'
    }),
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: GYAAN_COLOR_PRIMARY,
      // backgroundColor: GYAAN_COLOR_PRIMARY_HOVER
    }
  }
}));

export default useStyles;