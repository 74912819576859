import firebase from "firebase/app";
import "firebase/messaging";
import {
  GYAAN_FIREBASE_API_KEY,
  GYAAN_FIREBASE_AUTH_DOMAIN,
  GYAAN_FIREBASE_DATABASE_URL,
  GYAAN_FIREBASE_PROJECT_ID,
  GYAAN_FIREBASE_STORAGE_BUCKET,
  GYAAN_FIREBASE_MESSAGING_SENDER_ID,
  GYAAN_APP_ID,
  GYAAN_FIREBASE_PUBLIC_KEY
} from "./utils/constants";

const
  firebaseObject = firebase.initializeApp({
    apiKey: GYAAN_FIREBASE_API_KEY,
    authDomain: GYAAN_FIREBASE_AUTH_DOMAIN,
    databaseURL: GYAAN_FIREBASE_DATABASE_URL,
    projectId: GYAAN_FIREBASE_PROJECT_ID,
    storageBucket: GYAAN_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: GYAAN_FIREBASE_MESSAGING_SENDER_ID,
    appId: GYAAN_APP_ID
  })
;
let messaging = null;
if(firebase.messaging.isSupported()) {
  messaging = firebaseObject.messaging();
  messaging.usePublicVapidKey(GYAAN_FIREBASE_PUBLIC_KEY);
}

export {messaging};