import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

export default function ListItemLink(props) {
  const { icon, primary, secondary, to } = props;

  const renderLink = React.forwardRef((itemProps, ref) => (
    // with react-router-dom@^5.0.0 use `ref` instead of `innerRef`
    <RouterLink {...itemProps} innerRef={ref}/>
  ));

  return (
    <li>
      {(typeof to === 'function') ? (
        <ListItem component={renderLink} onClick={to} {...props}>
          {icon && <ListItemIcon>{icon}</ListItemIcon>}
          <ListItemText primary={primary} secondary={secondary} />
        </ListItem>
      ) : (
        <ListItem component={renderLink} to={to} {...props}>
          {icon && <ListItemIcon>{icon}</ListItemIcon>}
          <ListItemText primary={primary} secondary={secondary} />
        </ListItem>
      )}
    </li>
  );
}
