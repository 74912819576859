import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Typography,
  InputBase,
  Button,
  InputAdornment,
  LinearProgress,
  Link,
  withWidth
} from '@material-ui/core';
import ModalChangePassword from '../../components/ModalChangePassword';
import { Person, Lock, Business, VolumeUp } from '@material-ui/icons';
import { postLogin } from '../../utils/api';
import {
  GYAAN_RESPONSE_RESULT_OK,
  GYAAN_ROUTE_PAINEL_RESUMO,
  GYAAN_STORAGE_ITEM_CNPJ,
  GYAAN_STORAGE_ITEM_NAME,
  GYAAN_COLOR_ADORNMENT,
  GYAAN_FALLBACK_INPUT,
} from '../../utils/constants';
import useStyles from './style';
// import bg_video_mp4 from '../../videos/12121982-hd.mp4';
import bg_video_gif from '../../videos/12121982-hd.gif'
import logo_principal from '../../images/logo_gyaan_principal.png';
import audioGyaan from '../../audios/gyaan.mp4';

/*
* 1: http://marketingland.com/wp-content/ml-loads/2016/08/analytics-800x450.jpg
* */

export default withWidth()(({onLogin, width}) => {

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [auth, setAuth] = useState(false);
  // const [avatar] = useState(localStorage.getItem(GYAAN_STORAGE_ITEM_PHOTO)||GYAAN_FALLBACK_AVATAR);
  const [cnpj, setCnpj] = useState(localStorage.getItem(GYAAN_STORAGE_ITEM_CNPJ) || GYAAN_FALLBACK_INPUT);
  const [usuario, setUsuario] = useState(localStorage.getItem(GYAAN_STORAGE_ITEM_NAME) || GYAAN_FALLBACK_INPUT);
  const [senha, setSenha] = useState('');
  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const handleEnter = e => {
    closeSnackbar();
    if(e.key === "Enter")
      handleClick();
  };

  const handleClick = () => {
    if (!loading) {
      setLoading(true);
      postLogin({nm_usuario:usuario, nm_senha:senha, nr_cnpj:cnpj})
        .then(result => {
          if(result.status === GYAAN_RESPONSE_RESULT_OK) {
            closeSnackbar();
            setLoading(false);
            setAuth(true);
            onLogin(result);
          }else{
            enqueueSnackbar('Atenção, não foi possível confirmar suas informações de acesso.\nPor favor, verifique seu usuário e senha para continuar.', {
              variant: 'error',
              autoHideDuration: 10000,
              dense: (width === 'xs' || 'sm')
            });
            setLoading(false);
          }
	    })
    }
  };

  // const handleOpenModalPassword = () =>
  //   setOpen(true);

  const handleCloseModalPassword = () =>
    setOpen(false);

  const playHowToSpeakGYAAN = () =>
    (new Audio(audioGyaan)).play()
  ;

  if(auth){
    return (
      <Redirect to={GYAAN_ROUTE_PAINEL_RESUMO}/>
    );
  }

  return (
    <>
      {loading && (
        <LinearProgress style={{position: 'fixed', top: 0, left: 0, right: 0, zIndex: 101}}/>
      )}
      <div className={classes.root}>
        <div style={{position: 'fixed', bottom: 0, top: 0, left: 0, right: 0, backgroundColor: 'rgba(100,100,100,0.7)'}}/>
        <img src={bg_video_gif} alt={'n'} id={'gif-bg'}/>
        <div className={classes.login}>
          <div className={classes.welcome}>
            <Typography className={classes.innerWelcome}>
              Bem-vindo ao GYAAN<span style={{marginLeft: 8}} onClick={() => playHowToSpeakGYAAN()}><VolumeUp/></span>
            </Typography>
          </div>
          <div className={classes.userImageContainer}>
            <div className={classes.userImage}>
              <img className={classes.image} src={logo_principal} alt="Login" />
            </div>
          </div>
          <div style={{height: '60%', display: 'flex', alignItems: 'center', justifyContent: 'space-around', flexDirection: 'column'}}>
            <div className={classes.inputContainer}>
              <InputBase
                className={classes.input}
	              onKeyPress={handleEnter}
                onChange={e=>setCnpj(e.target.value)}
                startAdornment={
                  <InputAdornment position="start" style={{color: GYAAN_COLOR_ADORNMENT}}>
                    <Business />
                  </InputAdornment>
                }
                inputProps={{
                  pattern: '[0-9]*'
                }}
                placeholder="CNPJ"
                value={cnpj}
              />
            </div>
	          <div className={classes.inputContainer}>
              <InputBase
                className={classes.input}
                onKeyPress={handleEnter}
                onChange={e=>setUsuario(e.target.value)}
                startAdornment={
                  <InputAdornment position="start" style={{color: GYAAN_COLOR_ADORNMENT}}>
                    <Person />
                  </InputAdornment>
                }
                placeholder="Usuário"
                value={usuario}
              />
            </div>
            <div className={classes.inputContainer}>
              <InputBase
                type="password"
                className={classes.input}
                onKeyPress={handleEnter}
                onChange={e=>setSenha(e.target.value)}
                placeholder="Senha"
                startAdornment={
                  <InputAdornment position="start" style={{color: GYAAN_COLOR_ADORNMENT}}>
                    <Lock />
                  </InputAdornment>
                }
                value={senha}
              />
            </div>
            <div style={{width: '80%'}}>
              <Button
                className={classes.button}
                variant="contained"
                onClick={handleClick}
                fullWidth>
                Login
              </Button>
            </div>
            <Typography style={{display: 'flex', width: '80%', justifyContent: 'center', color: 'white', fontSize: 12}}>
              <Link component="button" variant="body2" color="inherit" onClick={() => enqueueSnackbar("Não está pronto ainda.", {variant: 'warning'})}>Esqueceu da senha?</Link>
              {/*<Link component="button" variant="body2" color="inherit" onClick={handleOpenModalPassword}>Esqueceu da senha?</Link>*/}
            </Typography>
          </div>
        </div>
      </div>
      <ModalChangePassword open={open} onClose={handleCloseModalPassword}/>
    </>
  )
});
