import React from 'react';
import Chart from '../../components/SimpleChart';
import Loading from '../../components/Loading';
import {GYAAN_QUERY_COMPRAS_DIARIAS} from '../../utils/constants';
import PainelCard from '../PainelCard';

const ChartComprasDiarias = () => {
  return (
    <>
      <PainelCard
        variant="chart"
        id_query={GYAAN_QUERY_COMPRAS_DIARIAS}
        headerProps={{enabled: false}}
        render={({data, loading}) => {
          return (
            <>
              {loading ? (<Loading/>) : (<Chart options={data}/>)}
            </>
          )
        }}
      />
    </>
  )
};

export default ChartComprasDiarias;
