import React from 'react';
import Ranking from '../Ranking';
import PainelCard from '../PainelCard';
import {GYAAN_QUERY_RANKING_GRUPOS} from '../../utils/constants';
//todo: arrumar bug de passar obrigatoriamente o enabled
export default () =>
  <PainelCard
    title="Ranking de grupos"
    variant="ranking"
    id_query={GYAAN_QUERY_RANKING_GRUPOS}
    share={false}
    headerProps={{
      bordered: true,
      enabled: true,
      pagination: true
    }}
    render={({pages, page, loading}) => {
      return (
        <Ranking
          loading={loading}
          ranking={pages}
          page={page}
          pagination
        />
      )
    }}
  />
;