import React from 'react';
import Chart from '../../components/SimpleChart';
import PainelCard from '../PainelCard';
import {GYAAN_QUERY_COMPRAS_MES_USUARIO} from "../../utils/constants";
import Loading from '../../components/Loading';

const ChartEvolucaoCompras = () => {
  return (
    <>
      <PainelCard
        variant="chart"
        id_query={GYAAN_QUERY_COMPRAS_MES_USUARIO}
        headerProps={{enabled: false}}
        render={({data, loading})=> {
          return (
            <>
              {loading ? (<Loading />) : (<Chart options={data}/>)}
            </>
          );
        }}
      />
    </>
  )
};

export default ChartEvolucaoCompras