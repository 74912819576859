import React from 'react';
import popoverHeaderBg from "../../../images/head_gb_sm_deep_orange.jpg";
import {Typography} from "@material-ui/core";

const DrawerHeader = ({username, company, photo}) => {
  return (
    <div style={{backgroundImage: `url(${popoverHeaderBg})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', height: 150, position: 'relative', marginBottom: 50}}>
      <div style={{position: 'absolute', bottom: -70/2, paddingLeft: 10 }}>
        <div style={{width: '100%', display: 'flex'}}>
          <div style={{width: 70, height: 70, borderRadius: '50%'}}>
            <img src={photo} alt="Lo" style={{width: '100%', borderRadius: '50%'}} />
          </div>
          <div style={{marginLeft: 10, paddingTop: 10}}>
            <Typography style={{color: 'white'}}>{username}</Typography>
            <Typography style={{color: '#666'}}>{company}</Typography>
          </div>
        </div>
      </div>
    </div>
  )
};

export default DrawerHeader;