import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root:{
    flexGrow: 1,
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

export default useStyles;