import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const MiniContentLoading = ({loading, primary, secondary, classes}) =>
  loading &&
    <>
      {primary &&
        <div
          className={clsx(
            classes['card-mini-loading__primary'],
            "animation-loading"
          )}
        />
      }
      {secondary &&
        <div
          className={clsx(
            classes['card-mini-loading__secondary'],
            "animation-loading"
          )}
        />
      }
    </>
  ;

MiniContentLoading.propTypes = {
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  primary: PropTypes.string,
  secondary: PropTypes.string
};

export { MiniContentLoading };
export default MiniContentLoading;