import { getTextColorByBrightness } from '../utils/utils';
export const

  GYAAN_ADDRESS_IMAGE_SERVER = process.env.NODE_ENV === 'production' ? 'https://sistema.mercadonarede.com.br/' : 'http://localhost/',
  GYAAN_FALLBACK_AVATAR = GYAAN_ADDRESS_IMAGE_SERVER + '0.png',
  GYAAN_FALLBACK_INPUT = '',

  GYAAN_MESSAGE_WORK_IN_PROGRESS = 'Opção ainda não implentada.',

  GYAAN_COLOR_ADORNMENT = '#bdbdbd',             //grey[400]
  GYAAN_COLOR_ADORNMENT_DARK = '#757575',        //grey[600]
  GYAAN_COLOR_PRIMARY = '#ff5722',               //deepOrange[500]
  GYAAN_COLOR_PRIMARY_LIGHT = '#ff8a65',         //deepOrange[300]
  GYAAN_COLOR_PRIMARY_DARK = '#e64a19',          //deepOrange[700]

  GYAAN_COLOR_SECONDARY = '#1976d2',             //blue[700]
  GYAAN_COLOR_SECONDARY_LIGHT = '#2196f3',       //blue[500]
  GYAAN_COLOR_SECONDARY_DARK = '#0d47a1',        //blue[900]

  GYAAN_COLOR_TEXT_PRIMARY = '#ffffff',          //white
  GYAAN_COLOR_TEXT_PRIMARY_LIGHT = getTextColorByBrightness(GYAAN_COLOR_PRIMARY_LIGHT),
  GYAAN_COLOR_TEXT_PRIMARY_DARK = getTextColorByBrightness(GYAAN_COLOR_PRIMARY_DARK),

  GYAAN_COLOR_TEXT_SECONDARY = getTextColorByBrightness(GYAAN_COLOR_SECONDARY),
  GYAAN_COLOR_TEXT_SECONDARY_LIGHT = getTextColorByBrightness(GYAAN_COLOR_SECONDARY_LIGHT),
  GYAAN_COLOR_TEXT_SECONDARY_DARK = getTextColorByBrightness(GYAAN_COLOR_SECONDARY_DARK),

  GYAAN_COLOR_DEFAULT = 'rgba(0, 0, 0, .54)',
  GYAAN_COLOR_DEFAULT_LIGHT = 'rgba(0, 0, 0, .08)',

  GYAAN_COLOR_CHART_TITLE = '#666666',
  GYAAN_COLORS_CHART = [
    "#F44336",
    "#E91E63",
    "#9C27B0",
    "#673AB7",
    "#3F51B5",
    "#2196F3",
    "#03A9F4",
    "#00BCD4",
    "#009688",
    "#4CAF50",
    "#8BC34A",
    "#CDDC39"
  ],
  GYAAN_CHART_SUBTITLE_ZOOM_DESKTOP = 'Pressione o mouse e arraste para dar o zoom na parte que quiser explorar melhor no gráfico',
  GYAAN_CHART_SUBTITLE_ZOOM_MOBILE = 'Use seus dedos para dar zoom na parte que quiser explorar melhor no gráfico',

  GYAAN_FIREBASE_API_KEY = 'AIzaSyDRJsis-3P2pRt6OiOK1McYIVVeAbN4KKE',
  GYAAN_FIREBASE_AUTH_DOMAIN = 'gyaan-fb4fb.firebaseapp.com',
  GYAAN_FIREBASE_DATABASE_URL = 'https://gyaan-fb4fb.firebaseio.com',
  GYAAN_FIREBASE_PROJECT_ID = 'gyaan-fb4fb',
  GYAAN_FIREBASE_STORAGE_BUCKET = '',
  GYAAN_FIREBASE_MESSAGING_SENDER_ID = '599732507065',
  GYAAN_APP_ID = '1:599732507065:web:d7ba71b9f1789fc7',
  GYAAN_FIREBASE_PUBLIC_KEY = 'BKApMcLTOtMxN-KO9TACjPjTUtjTFSVefnbUmhsspk4jvguaR0IfXCmXZT6bRdKbSUMmmZ3Y1Pi5C-KKUr_UC7U',

  GYAAN_STORAGE_ITEM_TOKEN = 'token',
  GYAAN_STORAGE_FIREBASE_TOKEN = 'gyaan-firebase-token',
  GYAAN_STORAGE_ITEM_USER = 'usuario',
  GYAAN_STORAGE_ITEM_CNPJ = 'nr_cnpj',
  GYAAN_STORAGE_ITEM_NAME = 'nm_usuario',
  GYAAN_STORAGE_ITEM_PHOTO = 'nm_arquivo',

  GYAAN_SIDEBAR_WIDTH = 56,
  GYAAN_BAR_WIDTH = 56,
  GYAAN_CHART_HEIGHT  = 400,
  GYAAN_PAINEL_CARD_MINI_HEIGHT = 200,
  GYAAN_PAINEL_CARD_RANKING_HEIGHT = 500,
  GYAAN_PAINEL_CARD_CHART_HEIGHT = 500,

  GYAAN_CHART_TYPE_RESUMIDO = '0',
  GYAAN_CHART_TYPE_DETALHADO = '1',

  GYAAN_QUERY_COMPRAS_DIARIAS = '1',
  GYAAN_QUERY_TOTAL_COMPRAS_DIA = '2',
  GYAAN_QUERY_COMPRAS_FORNECEDOR = '3',
  GYAAN_QUERY_COMPRAS_GRUPO = '4',
  GYAAN_QUERY_ITENS_COMPRADOS_USUARIO = '5',
  GYAAN_QUERY_ITENS_COTADOS_USUARIO = '6',
  GYAAN_QUERY_COMPRAS_USUARIO = '7',
  GYAAN_QUERY_COMPRAS_MES_USUARIO = '8',
  GYAAN_QUERY_RESUMO_GRUPO = '9',
  GYAAN_QUERY_RESUMO_FORNECEDORES = '10',
  GYAAN_QUERY_RESUMO_COMPRAS = '11',
  GYAAN_QUERY_ITENS_NAO_COTADOS = '12',
  GYAAN_QUERY_COMPRAS_DIARIAS_EMPRESAS = '13',
  GYAAN_QUERY_RANKING_MARCAS = '14',
  GYAAN_QUERY_RANKING_GRUPOS = '15',
  GYAAN_QUERY_DRILL_FORNECEDORES = '16',
  GYAAN_QUERY_DRILL_GRUPOS = '17',
  GYAAN_QUERY_COMPRADORES = '18',

  GYAAN_PERIODO_ONTEM = '1',
  GYAAN_PERIODO_SETE_DIAS = '2',
  GYAAN_PERIODO_QUINZE_DIAS = '3',
  GYAAN_PERIODO_TRINTA_DIAS = '4',
  GYAAN_PERIODO_TRES_MESES = '5',
  GYAAN_PERIODO_SEIS_MESES = '6',
  GYAAN_PERIODO_DOZE_MESES = '7',
  GYAAN_PERIODO_SEMANA = '8',
  GYAAN_PERIODO_QUINZENA = '9',
  GYAAN_PERIODO_MES = '10',
  GYAAN_PERIODO_TRIMESTRE = '11',
  GYAAN_PERIODO_SEMESTRE = '12',
  GYAAN_PERIODO_ANO = '13',

  GYAAN_PERIODO_ANTERIOR = '14',
  GYAAN_PERIODO_ANO_ANTERIOR = '15',

  STRING_PERIODO_ONTEM = 'Ontem',
  STRING_PERIODO_SETE_DIAS = 'Últimos 7 dias',
  STRING_PERIODO_QUINZE_DIAS = 'Últimos 15 dias',
  STRING_PERIODO_TRINTA_DIAS = 'Últimos 30 dias',
  STRING_PERIODO_TRES_MESES = 'Últimos 3 meses',
  STRING_PERIODO_SEIS_MESES = 'Últimos 6 meses',
  STRING_PERIODO_DOZE_MESES = 'Últimos 12 meses',
  STRING_PERIODO_SEMANA = 'Esta semana',
  STRING_PERIODO_QUINZENA = 'Esta quinzena',
  STRING_PERIODO_MES = 'Este mês',
  STRING_PERIODO_TRIMESTRE = 'Este trimestre',
  STRING_PERIODO_SEMESTRE = 'Este semestre',
  STRING_PERIODO_ANO = 'Este ano',
  STRING_PERIODO_ANTERIOR = 'Período anterior',
  STRING_PERIODO_ANO_ANTERIOR = 'Ano anterior',
  STRING_CURRENCY_SYMBOL = 'R$ ',

  GYAAN_PERIODOS = [
    [GYAAN_PERIODO_ONTEM, STRING_PERIODO_ONTEM],
    [GYAAN_PERIODO_SETE_DIAS, STRING_PERIODO_SETE_DIAS],
    [GYAAN_PERIODO_QUINZE_DIAS, STRING_PERIODO_QUINZE_DIAS],
    [GYAAN_PERIODO_TRINTA_DIAS, STRING_PERIODO_TRINTA_DIAS],
    [GYAAN_PERIODO_TRES_MESES, STRING_PERIODO_TRES_MESES],
    [GYAAN_PERIODO_SEIS_MESES, STRING_PERIODO_SEIS_MESES],
    [GYAAN_PERIODO_DOZE_MESES, STRING_PERIODO_DOZE_MESES],
    [GYAAN_PERIODO_SEMANA, STRING_PERIODO_SEMANA],
    [GYAAN_PERIODO_QUINZENA, STRING_PERIODO_QUINZENA],
    [GYAAN_PERIODO_MES, STRING_PERIODO_MES],
    [GYAAN_PERIODO_TRIMESTRE, STRING_PERIODO_TRIMESTRE],
    [GYAAN_PERIODO_SEMESTRE, STRING_PERIODO_SEMESTRE],
    [GYAAN_PERIODO_ANO, STRING_PERIODO_ANO],
  ],

  GYAAN_PERIODOS_COMPARATIVOS = [
    [GYAAN_PERIODO_ANTERIOR, STRING_PERIODO_ANTERIOR],
    [GYAAN_PERIODO_ANO_ANTERIOR, STRING_PERIODO_ANO_ANTERIOR]
  ],

  GYAAN_TAB_PAINEL = 'painel',
  GYAAN_TAB_INSIGHTS = 'insights',
  GYAAN_TAB_FAVORITOS = 'favoritos',
  GYAAN_TAB_CONFIGURACOES = 'configuracoes',
  GYAAN_TAB_NOTIFICACOES = 'notificacoes',
  GYAAN_TAB_PERFIL = 'perfil',
  GYAAN_TAB_LOGOUT = 'logout',
  GYAAN_TAB_TESTES = 'testes',

  STRING_TAB_PAINEL = 'Painel',
  STRING_TAB_INSIGHTS = 'Insights',
  STRING_TAB_FAVORITOS = 'Favoritos',
  STRING_TAB_CONFIGURACOES = 'Configurações',
  STRING_TAB_NOTIFICACOES = 'Notificações',
  STRING_TAB_TESTES = 'Testes',

  GYAAN_TABS = [
    [GYAAN_TAB_PAINEL, STRING_TAB_PAINEL],
    [GYAAN_TAB_INSIGHTS, STRING_TAB_INSIGHTS],
    [GYAAN_TAB_FAVORITOS, STRING_TAB_FAVORITOS],
    [GYAAN_TAB_CONFIGURACOES, STRING_TAB_CONFIGURACOES],
    [GYAAN_TAB_NOTIFICACOES, STRING_TAB_NOTIFICACOES],
    [GYAAN_TAB_TESTES, STRING_TAB_TESTES]
  ],

  GYAAN_TAB_PAINEL_RESUMO = 'resumo',
  GYAAN_TAB_PAINEL_COTACOES = 'cotacoes',
  GYAAN_TAB_PAINEL_PEDIDOS = 'pedidos',

  GYAAN_TAB_TESTES_SELECTIONBOX = 'selectionbox',
  GYAAN_TAB_TESTES_WIDGETS = 'widgets',

  GYAAN_ROUTE_LOGIN = '/',
  GYAAN_ROUTE_PAINEL = '/painel',
  GYAAN_ROUTE_PAINEL_RESUMO = `${GYAAN_ROUTE_PAINEL}/resumo`,
  GYAAN_ROUTE_PAINEL_COTACOES = `${GYAAN_ROUTE_PAINEL}/cotacoes`,
  GYAAN_ROUTE_PAINEL_PEDIDOS = `${GYAAN_ROUTE_PAINEL}/pedidos`,

  GYAAN_ROUTE_FAVORITOS = '/favoritos',

  GYAAN_ROUTE_TESTES = `/testes`,
  GYAAN_ROUTE_TESTES_SELECTIONBOX = `${GYAAN_ROUTE_TESTES}/selectionbox`,
  GYAAN_ROUTE_TESTES_WIDGETS = `${GYAAN_ROUTE_TESTES}/widgets`,
  GYAAN_ROUTE_TESTES_LOADING = `${GYAAN_ROUTE_TESTES}/loading`,

  GYAAN_STATUS_RESPONSE_NO_LENGTH = 0,
  GYAAN_STATUS_RESPONSE_OK = 1,

  GYAAN_RESPONSE_RESULT_OK = 1,
  GYAAN_RESPONSE_SERVER_ERROR = 0,
  GYAAN_RESPONSE_RESULT_UNAUTHORIZED = -1,

  GYAAN_PAGINATION_RANKING_LIMIT = 11,

  iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent),

  HIGHCHART_OPTIONS = {
    chart: {
      marginTop: GYAAN_CHART_HEIGHT / 2 - 120,
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      style: {
        fontFamily: '"Roboto", sans-serif',
      }
    },
    tooltip: {
      backgroundColor: 'rgba(255, 255, 255, .9)'
    },
    yAxis: {
      y: 0,
      min: 0,
      gridLineDashStyle: "Dot",
    },
    title: {
      style: {
        color: GYAAN_COLOR_CHART_TITLE,
      }
    },
    subtitle: {
      align: 'left'
    },
    plotOptions: {
      spline: {
        states: {
          hover: {
            enabled: false,
          }
        }
      },
      area: {
        lineWidth: 1,
        threshold: null,
        marker: {
          enabled: false,
          symbol: 'circle',
          radius: 2,
          states: {
            hover: {
              enabled: false,
            }
          }
        }
      },
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false
        },
        showInLegend: true
      }
    },
    credits:{
      enabled: false
    },
    lang: {
      numericSymbols: ['k', 'M', 'G', 'T', 'P', 'E'],
      printChart: 'Imprimir gráfico',
      downloadCSV: 'Download arquivo CSV',
      downloadJPEG: 'Download imagem JPEG',
      downloadPDF: 'Download arquivo PDF',
      downloadPNG: 'Download imagem PNG',
      downloadSVG: 'Download vetor SVG',
      downloadXLS: 'Download arquivo XLS',
      viewData: 'Visualizar tabela',
      viewFullscreen: 'Visualizar em tela cheia',
      drillUpText: '◁ Voltar para {series.name}'
    },
    global: {
      useUTC: false
    },
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          enabled: true,
          menuItems: ['viewFullScreen', 'printChart', 'downloadPNG', 'downloadJPEG', 'downloadCSV', 'downloadXLS', 'downloadPDF', 'viewData']
        }
      }
    },
  }
;
