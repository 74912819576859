import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export default ({component: Component, usuario, token, ...rest}) => 
  <Route
    {...rest}
    render={ props => (
      usuario !== null && token !== null
        ? <Component {...rest} {...props.match.params} match={props.match} history={props.history} usuario={usuario}  />
	      : <Redirect to={{pathname:'/', state: props.location}} />
    )}
  />
;
