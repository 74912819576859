import {
  GYAAN_PERIODO_ONTEM,
  GYAAN_PERIODO_SETE_DIAS,
  GYAAN_PERIODO_QUINZE_DIAS,
  GYAAN_PERIODO_TRINTA_DIAS,
  GYAAN_PERIODO_TRES_MESES,
  GYAAN_PERIODO_SEIS_MESES,
  GYAAN_PERIODO_DOZE_MESES,
  GYAAN_PERIODO_SEMANA,
  GYAAN_PERIODO_QUINZENA,
  GYAAN_PERIODO_MES,
  GYAAN_PERIODO_SEMESTRE,
  GYAAN_PERIODO_ANO,
  GYAAN_PERIODO_TRIMESTRE,
  GYAAN_PERIODOS,
  GYAAN_TABS,
  GYAAN_STORAGE_ITEM_USER,
  GYAAN_ROUTE_PAINEL_RESUMO,
  GYAAN_TAB_PAINEL,
  GYAAN_STORAGE_FIREBASE_TOKEN
} from './constants';
import {registerDevice} from "./api";

export const

  useUser = key => {
    try{
      const user = JSON.parse(sessionStorage.getItem(GYAAN_STORAGE_ITEM_USER));
      if(key && user.hasOwnProperty(key))
        return user[key];
      return user;
    }catch(ex){
      return null;
    }
  },

  usePeriod = id_query => {
    try{
      if(!id_query)
        return GYAAN_PERIODO_SETE_DIAS;
      const user = JSON.parse(sessionStorage.getItem(GYAAN_STORAGE_ITEM_USER));
      const query = user.queries.filter(({id_sql}) => parseInt(id_query,10) === parseInt(id_sql,10))[0];
      if('id_periodo' in query)
        return query['id_periodo'].toString();
      return GYAAN_PERIODO_SETE_DIAS;
    }catch (e) {
      return GYAAN_PERIODO_SETE_DIAS;
    }
  },

  useFavorite = id_query => {
    try{
      if(!id_query)
        return false;
      const user = JSON.parse(sessionStorage.getItem(GYAAN_STORAGE_ITEM_USER));
      const query = user.queries.filter(({id_sql}) => parseInt(id_query,10) === parseInt(id_sql,10))[0];
      if('cs_favorito' in query)
        return query['cs_favorito'].toString()==='1';
      return false;
    }catch (e) {
      return false;
    }
  },

  setSessionPeriod = (id_query, id_period) => {
    if(!id_query || !id_period)
      return null;
    try{
      const user = JSON.parse(sessionStorage.getItem(GYAAN_STORAGE_ITEM_USER));
      const query = user.queries.filter(({id_sql}) => parseInt(id_query,10) === parseInt(id_sql,10))[0];
      if('id_periodo' in query) {
        query['id_periodo'] = id_period;
        sessionStorage.setItem(GYAAN_STORAGE_ITEM_USER, JSON.stringify(user));
      }
    }catch (e) {
      console.warn('Tentou gravar novo periodo mas nao conseguiu: ', {id_query, id_period});
      return null;
    }
  },

  setSessionFavorite = (id_query, is_favorite=false) => {
    if(!id_query)
      return null;
    try{
      const user = JSON.parse(sessionStorage.getItem(GYAAN_STORAGE_ITEM_USER));
      const query = user.queries.filter(({id_sql}) => parseInt(id_query,10) === parseInt(id_sql,10))[0];
      if('cs_favorito' in query) {
        query['cs_favorito'] = is_favorite?'1':'0';
        sessionStorage.setItem(GYAAN_STORAGE_ITEM_USER, JSON.stringify(user));
      }
      return user;
    }catch (e) {
      console.warn('Tentou gravar novo favorite mas nao conseguiu: ', {id_query, is_favorite});
      return null;
    }
  },

  getImageSrc = (series, index) =>
    series.filter((a, i) => index === i).map((a) => a.nm_arquivo)[0]
  ,

  labelFormatterVertical = (src, value) =>
    `
    <div style='width: 140px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;font-size: 11px;display: flex;align-items: center'>
      <img src='${src}' style='width: 40px; height: 40px; border-radius: 50%; margin-right: 10px'/>${value}
    </div>
    `
  ,

  labelFormatterHorizontal = (src, value) =>
    `
    <div style='white-space: nowrap; overflow: hidden; text-overflow: ellipsis;font-size: 11px;display: flex;align-items: center;flex-direction: column'>
      <img src='${src}' style='width: 40px; height: 40px; border-radius: 50%; margin-bottom: 10px'/>${value}
    </div>
    `
  ,

  getTime = days => (24*60*60*1000) * days,

  getToday = () => process.env.NODE_ENV === 'production' ? new Date() : fakeToday(),

  fakeToday = () => new Date(2014, 7, 1),

  getYesterday = (today=fakeToday()) => new Date(today.getTime() - getTime(1)),

  formatDate = date => [
      date.getFullYear(),
      (date.getMonth()+1).toString().padStart(2,'0'),
      date.getDate().toString().padStart(2,'0')
    ].join('')
  ,

  getTextFromArray = (content=[], index) => content.filter(each => each[0] === index)[0][1],

  getTextFromPeriod = period => getTextFromArray(GYAAN_PERIODOS, period),

  getTextFromTab = tab => getTextFromArray(GYAAN_TABS, tab),

  getPeriod = (period, today=getToday()) => {

    let
      start = new Date(today),
      end = new Date(today)
    ;
    end.setTime(today.getTime()-getTime(1));

    switch (period) {
      case GYAAN_PERIODO_SETE_DIAS:
        start.setTime(today.getTime() - getTime(7));
        break;
      case GYAAN_PERIODO_QUINZE_DIAS:
        start.setTime(today.getTime() - getTime(15));
        break;
      case GYAAN_PERIODO_TRINTA_DIAS:
        start.setTime(today.getTime() - getTime(30));
        break;
      case GYAAN_PERIODO_TRES_MESES:
        start.setTime(today.getTime() - getTime(90));
        break;
      case GYAAN_PERIODO_SEIS_MESES:
        start.setTime(today.getTime() - getTime(180));
        break;
      case GYAAN_PERIODO_DOZE_MESES:
        start.setTime(today.getTime() - getTime(360));
        break;
      case GYAAN_PERIODO_SEMANA:
        const dayOfWeek = today.getDay();
        if(dayOfWeek !== 0){
          start.setTime(today.getTime() - getTime(dayOfWeek));
          end.setTime(today.getTime() + getTime(6-dayOfWeek));
        }
        break;
      case GYAAN_PERIODO_QUINZENA:
        start.setFullYear(today.getFullYear(), today.getMonth(), 1);
        if (today.getDate() > 16)
          start.setFullYear(today.getFullYear(), today.getMonth(), 16);
        end.setTime(start.getTime() + getTime(14));
        break;
      case GYAAN_PERIODO_MES:
        start.setFullYear(today.getFullYear(), today.getMonth(), 1);
        end.setFullYear(today.getFullYear(), today.getMonth() + 1, 0);
        break;
      case GYAAN_PERIODO_TRIMESTRE:
        const month = today.getMonth();
        if (month >= 0 && month <= 2) {
          start.setFullYear(today.getFullYear(), 0, 1);
          end.setFullYear(today.getFullYear(), 3, 1);
        }
        else if (month > 2 && month <= 5) {
          start.setFullYear(today.getFullYear(), 3, 1);
          end.setFullYear(today.getFullYear(), 6, 0);
        }
        else if (month > 5 && month <= 8) {
          start.setFullYear(today.getFullYear(), 6, 1);
          end.setFullYear(today.getFullYear(), 9, 0);
        }
        else if (month > 8 && month <= 11) {
          start.setFullYear(today.getFullYear(), 9, 1);
          end.setFullYear(today.getFullYear(), 12, 0);
        }
        break;
      case GYAAN_PERIODO_SEMESTRE:
        if (today.getMonth() >= 0 && today.getMonth() <= 5) {
          start.setFullYear(today.getFullYear(), 0, 1);
          end.setFullYear(today.getFullYear(), 6, 0);
        } else {
          start.setFullYear(today.getFullYear(), 6, 1);
          end.setFullYear(today.getFullYear(), 12, 0);
        }
        break;
      case GYAAN_PERIODO_ANO:
        start.setFullYear(today.getFullYear(), 0, 1);
        end.setFullYear(today.getFullYear(), 12, 0);
        break;
      case GYAAN_PERIODO_ONTEM:
      default:
        start.setTime(today.getTime() - getTime(1));
        end.setTime(today.getTime() - getTime(1));
    }
    return {start, end};
  },

  capitalize = string =>
    string.substr(0, 1).toUpperCase() + string.substr(1, string.length - 1),

  toNumber = value =>
    parseFloat(value.replace(/"."/g, "").replace(/,/g,".")),

  toCurrency = value =>
    value.toLocaleString("pt-BR",{style: 'currency', currency: 'BRL'}),

  formatCurrency = (amount, decimalCount = 2, decimal = ",", thousands = ".") => {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

      const negativeSign = amount < 0 ? "-" : "";

      let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      let j = (i.length > 3) ? i.length % 3 : 0;

      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
      console.log(e)
    }
  },

  mathVariance = (v1, v2) =>((v2-v1)/v1)*100,

  showNotification = (title, message) => {
    if("Notification" in window){
      return new Notification(title, {
        body: message
      });
    }
  },

  getRouteByTab = tab => {
    switch (tab) {
      case GYAAN_TAB_PAINEL:
        return GYAAN_ROUTE_PAINEL_RESUMO;
      default:
        return '';
    }
  },

  getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  },

  /**
   * Calculate brightness value by RGB or HEX color.
   * @param color (String) The color value in RGB or HEX (for example: #000000 || #000 || rgb(0,0,0) || rgba(0,0,0,0))
   * @returns (Number) The brightness value (dark) 0 ... 255 (light)
   */
  brightnessByColor = color => {
    let
      isHEX = color.indexOf("#") === 0,
      isRGB = color.indexOf("rgb") === 0,
      r = null,
      g = null,
      b = null
    ;

    if (isHEX) {
      let m = color.substr(1).match(color.length === 7 ? /(\S{2})/g : /(\S{1})/g);
      if (m) {
        r = parseInt(m[0], 16);
        g = parseInt(m[1], 16);
        b = parseInt(m[2], 16);
      }
    }

    if (isRGB) {
      let m = color.match(/(\d+){3}/g);
      if (m) {
        r = m[0];
        g = m[1];
        b = m[2];
      }
    }

    if (r !== null) {
      return ((r * 299) + (g * 587) + (b * 114)) / 1000;
    }
  },

  getTextColorByBrightness = color =>
    brightnessByColor(color) < 255/2
      ? '#FFFFFF'
      : '#000000'
  ,

  //Todo(1) - Refatorar este codigo

  getBodyNotification = (body, tag) => ({
    icon:'/static/icons/icon-192x192.png',
    badge:'/static/icons/icon-144x144.png',
    body,
    tag
  }),

  handleNotification = data => {
    if(data && "id_action" in data)
      switch (data.id_action) {
        case '1':
          return new Notification(
            'Gyaan Informa',
            getBodyNotification(
              "Sua empresa economizou mais de R$ 100 mil nos últimos 3 meses",
              "gyaan-inside"
            )
          );
        default:
          return null;
      }
  },

  registerFirebaseServiceWorker = messaging => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .getRegistration('/')
        .then(registration => {
          messaging.useServiceWorker(registration);
          messaging
            .requestPermission()
            .then(async function () {
              const token = await messaging.getToken();
              const token_old = localStorage.getItem(GYAAN_STORAGE_FIREBASE_TOKEN);
              if (token_old !== token) {
                registerDevice({token, token_old})
                  .then(({status}) => {
                    if (status === 1)
                      localStorage.setItem(GYAAN_STORAGE_FIREBASE_TOKEN, token);
                  })
              }
            })
        })
        .catch(err => {
          console.error('REGISTRAR FIREBASE ERROR', err);
        });
      messaging.onMessage(payload=>{
        if(payload)
          handleNotification(payload.data);
      });
    }
  }
;