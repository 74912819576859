import {grey} from '@material-ui/core/colors/index';
import {
  GYAAN_COLOR_PRIMARY,
  GYAAN_COLOR_PRIMARY_LIGHT,
  GYAAN_SIDEBAR_WIDTH,
} from '../../utils/constants';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles( theme => ({
  root: {
    width: GYAAN_SIDEBAR_WIDTH,
    display: 'flex',
    flexDirection: 'row',
    position: 'fixed',
    top: 0,
    bottom: 0,
    zIndex: 1000
  },
  navPrimary: {
    width: 70,
    backgroundColor: GYAAN_COLOR_PRIMARY,
    boxShadow: `5px 0 10px -2px ${grey[500]}`
  },
  navSecondary: {
    padding: 10,
    flexGrow: 1,
    backgroundColor: 'white',
    boxShadow: `10px 0 5px -2px ${grey[300]}`,
  },
  toolbar: {
    width: '100%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  sidebarHeader: {
    width: '100%',
    padding: '10px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconButton: {
    color: 'white',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, .15)'
    }
  },
  iconButtonActive: {
    backgroundColor: 'rgba(255, 255, 255, .15)'
  },
  marginBottom: {
    marginBottom: 5
  },
  listItem: {
    borderRadius: 5,
    marginBottom: 5,
    paddingTop: 2,
    paddingBottom: 2,
    '&:hover': {
      backgroundColor: GYAAN_COLOR_PRIMARY_LIGHT,
      borderRadius: 5,
    }
  },
  listItemSelected: {
    backgroundColor: `${GYAAN_COLOR_PRIMARY_LIGHT} !important`
  },
  popoverPaper: {
    width: 225,
    height: 145,
    overflowX: 'hidden',
    overflowY: 'hidden'
  },
  sidebarAction: {
    marginTop: 10,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  spacer: {
    flex: 1
  },
  gutterTop: {
    marginTop: 10
  },
  gutterBottom: {
    marginBottom: 10
  },
  hidden: {
    display: 'none'
  }
}));

export default useStyles;