import {makeStyles} from "@material-ui/core";

//TODO: arrumar variaveis e classnames
const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%'
  },
  chart: {
    position: 'relative',
    width: props => props.size,
    height: props => props.size,
    background: props => props.base,
    borderRadius: '50%',
    overflow: 'hidden'
  },
  one: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    clip: props => `rect(0 ${props.size}px ${props.size/2}px 0)`,
    transform: props => `rotate(${props.deg1})`,
    background: props => props.color
  },
  two: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    clip: props => `rect(0 ${props.size/2}px ${props.size}px 0)`,
    transform: props => `rotate(${props.deg2})`,
    background: props => props.color2
  },
  chartCenter: {
    position: 'absolute',
    borderRadius: '50%'
  },
  chartCenterProps: props => ({
    top: props.width,
    left: props.width,
    width: props.size - (props.width * 2),
    height: props.size - (props.width * 2),
    background: props.center
  }),
  percent: {
    display: 'block',
    textAlign: 'center',
    fontSize: props => props.textSize,
    lineHeight: props => `${props.size - (props.width * 2)}px`,
    color: props => props.textColor
  }
});

export default useStyles;