import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'typeface-roboto';
import GyaanApp from './GyaanApp';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import Grow from '@material-ui/core/Grow';

if("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/sw.js", {scope:'/'})
    .then(registration => {
      console.log({registration, scope:registration.scope});
    })
    .catch(err => {
      console.log("Erro ao registrar service worker", {err});
    });
}

const props = {
  maxSnack: 3,
  preventDuplicate: false,
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  TransitionComponent: Grow
};

ReactDOM.render(
  <BrowserRouter>
    <SnackbarProvider {...props}>
      <GyaanApp />
    </SnackbarProvider>
  </BrowserRouter>,
  document.getElementById('root')
);