import React from 'react';
import Loading from '../../components/Loading';
import Chart from '../../components/SimpleChart';
import {GYAAN_QUERY_COMPRAS_FORNECEDOR} from '../../utils/constants';
import PainelCard from '../PainelCard';

const ChartComprasFornecedores = () => {
  return (
    <>
      <PainelCard
        variant="chart"
        id_query={GYAAN_QUERY_COMPRAS_FORNECEDOR}
        headerProps={{enabled: false}}
        footerProps={{
          enabled: true,
          comparisonEnabled: false
        }}
        render={({data, loading}) => {
          return (
            <>
              {loading ? (
                <Loading/>
              ) : (
                <Chart options={data}/>
              )}
            </>
          )
        }}
      />
  </>);
};

export default ChartComprasFornecedores;
