import React from 'react';
import PropTypes from 'prop-types';
import {Typography} from '@material-ui/core';

const MiniContentSecondary = ({loading, secondary, currency, classes}) =>
  !loading &&
    <div className={classes['mini-content__secondary']}>
      {currency && <Typography><small>{currency}</small></Typography>}
      <Typography className={classes.secondary}>{secondary}</Typography>
    </div>
  ;

MiniContentSecondary.propTypes = {
  loading: PropTypes.bool,
  secondary: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  currency: PropTypes.string
};

export { MiniContentSecondary };
export default MiniContentSecondary;