import React from 'react';
import clsx from 'clsx';
import { Tooltip, IconButton } from '@material-ui/core';
import useStyles from '../style';

export default ({title, active, value, icon, show, onClick, to, anchorPopover}) => {
  const classes = useStyles();
  return (
    <Tooltip title={title} placement="right">
      <IconButton
        aria-describedby={Boolean(anchorPopover) ? 'notifications-popover' : undefined}
        className={clsx(
          classes.iconButton,
          classes.marginBottom, active && classes.iconButtonActive
        )}
        onClick={() => onClick(value, to)}
        value={value}
      >
        {icon}
      </IconButton>
    </Tooltip>
  );
};