import React from "react";
import Loading from '../Loading';
import PainelCard from "../PainelCard";
import Chart from "../SimpleChart";
import {GYAAN_QUERY_COMPRADORES} from "../../utils/constants";
import {getImageSrc, labelFormatterHorizontal} from "../../utils/utils";

const fakeData = [
  [
    {
      "colorByPoint": false,
      "data": [
        {
          "name": "Michael",
          "y": 1502
        },
        {
          "name": "aline",
          "y": 5651
        },
        {
          "name": "alinesilva",
          "y": 29
        },
        {
          "name": "diesley",
          "y": 378
        },
        {
          "name": "renata",
          "y": 565
        },
        {
          "name": "rodrigolana",
          "y": 532
        },
        {
          "name": "giliard",
          "y": 23479
        },
        {
          "name": "walmir",
          "y": 3339
        },
        {
          "name": "cunha",
          "y": 2284
        }
      ],
      "name": "Itens comprados",
      "type": "column",
      "yAxis": 1,
      "tooltip":{
        "valueSuffix":"<br>"
      },
    },
    {
      "colorByPoint": false,
      "data": [
        {
          "name": "Michael",
          "y": 1504
        },
        {
          "name": "aline",
          "y": 5698
        },
        {
          "name": "alinesilva",
          "y": 105
        },
        {
          "name": "diesley",
          "y": 451
        },
        {
          "name": "renata",
          "y": 576
        },
        {
          "name": "rodrigolana",
          "y": 620
        },
        {
          "name": "giliard",
          "y": 23090
        },
        {
          "name": "walmir",
          "y": 3628
        },
        {
          "name": "cunha",
          "y": 2314
        }
      ],
      "tooltip":{
        "valueSuffix":"<br>"
      },
      "name": "Itens solicitados",
      "type": "column",
      "yAxis": 2
    },
    {
      "colorByPoint": false,
      "tooltip":{
        "valueSuffix":"<br>"
      },
      "data": [
        {
          "name": "Michael",
          "y": 714151
        },
        {
          "name": "aline",
          "y": 3470340
        },
        {
          "name": "alinesilva",
          "y": 5905
        },
        {
          "name": "diesley",
          "y": 177343
        },
        {
          "name": "renata",
          "y": 238002
        },
        {
          "name": "rodrigolana",
          "y": 133946
        },
        {
          "name": "giliard",
          "y": 13315633
        },
        {
          "name": "walmir",
          "y": 1621035
        },
        {
          "name": "cunha",
          "y": 679744
        }
      ],
      "name": "Valor comprado",
      "type": "spline"
    }
  ]
];

const getData = (data) => {
  if(data.series.length && Object.keys(data.series[0]).length)
    return data;
  data['series'] = fakeData[0];
  data['payload'] = [
    {"id_usuario_responsavel":16420,"nm_arquivo":"http://localhost/upload/usuarios/min/33.jpg","nm_usuario":"aline"},
    {"id_usuario_responsavel":15818,"nm_arquivo":"http://localhost/upload/usuarios/min/0.png","nm_usuario":"rodrigolana"},
    {"id_usuario_responsavel":17238,"nm_arquivo":"http://localhost/upload/usuarios/min/0.png","nm_usuario":"giliard"},
    {"id_usuario_responsavel":22290,"nm_arquivo":"http://localhost/upload/usuarios/min/33.jpg","nm_usuario":"walmir"},
    {"id_usuario_responsavel":16420,"nm_arquivo":"http://localhost/upload/usuarios/min/33.jpg","nm_usuario":"aline"},
    {"id_usuario_responsavel":15818,"nm_arquivo":"http://localhost/upload/usuarios/min/0.png","nm_usuario":"rodrigolana"},
    {"id_usuario_responsavel":17238,"nm_arquivo":"http://localhost/upload/usuarios/min/0.png","nm_usuario":"giliard"},
    {"id_usuario_responsavel":22290,"nm_arquivo":"http://localhost/upload/usuarios/min/33.jpg","nm_usuario":"walmir"},
    {"id_usuario_responsavel":22290,"nm_arquivo":"http://localhost/upload/usuarios/min/33.jpg","nm_usuario":"walmir"},
  ];
  return data;
};

const ChartCompradores = () => {
  return (
    <PainelCard
      variant="chart"
      id_query={GYAAN_QUERY_COMPRADORES}
      headerProps={{enabled: false}}
      footerProps={{enabled: true, comparisonEnabled: false}}
      render={({data, loading}) => {
        return (
          <>
            {loading ?
              (<Loading/>) :
              (<Chart
                options={getData(data)}
                labelFormatter={function () {
                  return labelFormatterHorizontal(getImageSrc(getData(data).payload, this.pos), this.value === 'Não identificado' ? 'Anônimo' : this.value);
                }} />
              )}
          </>
        )
      }}
    />);
};

export default ChartCompradores;