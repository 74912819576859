import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import useStyles from '../style.js';

const PainelCardContent = ({children, variant}) => {
  const classes = useStyles();
  return (
    <div
      id="elemento"
      className={clsx({
        [classes['card-ranking-content']]: variant === 'ranking',
        [classes['card-chart-content']]: variant === 'chart',
        [classes['card-mini-content']]: variant === 'mini',
        /*[classes['card-mini-content__column']]: secondary*/
      })}
    >
      {children}
    </div>
  )
};

PainelCardContent.propTypes = {
  children: PropTypes.any.isRequired,
  variant: PropTypes.string.isRequired
};

export { PainelCardContent };
export default PainelCardContent;