import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Drawer from '../Drawer';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Badge
} from '@material-ui/core';
import {
  Menu,
  NotificationsOutlined,
  RefreshOutlined
} from '@material-ui/icons';
import useStyles from "./style";

const MobileBar = ({usuario, tab, title, anchorNotifications, onOpenNotificationsPopover, onChangeTab, hasNotifications, onLogout}) => {
  const classes = useStyles();
  const [openDrawer, setDrawerOpen] = useState(false);

  const handleToggleDrawer = open => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift'))
      return;
    setDrawerOpen( open );
  };

  return (
    <>
      <AppBar position="fixed" className={classes.appBarMobile}>
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={handleToggleDrawer(true)}
          >
            <Menu />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
          <IconButton
            onClick={e => {window.location.reload()}}
          >
            <RefreshOutlined
              style={{color: 'white'}}
            />
          </IconButton>
          <IconButton
            aria-describedby={Boolean(anchorNotifications) ? 'notifications-popover' : undefined}
            onClick={e => onOpenNotificationsPopover(e, tab)}
          >
            {hasNotifications ? (
              <Badge
                variant="standard"
                badgeContent={3}
                max={9}
                color="primary"
                classes={{colorPrimary: classes.primaryMobile}}
              >
                <NotificationsOutlined className={hasNotifications ? 'animation-ring' : ''} style={{color: 'white'}}/>
              </Badge>
            ) : (
              <NotificationsOutlined style={{color: 'white'}}/>
            )}
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        open={openDrawer}
        onToggle={handleToggleDrawer}
        usuario={usuario}
        onChangeTab={onChangeTab}
        tab={tab}
        onLogout={onLogout}
      />
    </>
  )
};

MobileBar.propTypes = {
  hasNotifications: PropTypes.bool,
  usuario: PropTypes.object.isRequired,
  tab: PropTypes.any.isRequired,
  title: PropTypes.string,
  anchorNotifications: PropTypes.any,
  onOpenNotificationsPopover: PropTypes.func,
  onChangeTab: PropTypes.func,
  openDrawer: PropTypes.func,
  onLogout: PropTypes.func
};

export default MobileBar;