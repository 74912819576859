import React from 'react';
import {GYAAN_QUERY_ITENS_NAO_COTADOS} from '../../utils/constants';
import Loading from "../Loading";
import PainelCard from "../PainelCard";
import Chart from "../SimpleChart";

const ChartItensNaoCotados = () => {
  return (
    <>
      <PainelCard
        variant="chart"
        id_query={GYAAN_QUERY_ITENS_NAO_COTADOS}
        headerProps={{enabled: false}}
        render={({data, loading})=>{
          return (
            <>{loading ? (<Loading />) : (<Chart options={data}/>)}</>
          )
        }}
      />
    </>
  );
};

export default ChartItensNaoCotados;