import React from 'react';
import PropTypes from 'prop-types';
import bg_content from '../../../images/iconbox_bg.png';

const MiniContentBackground = ({loading, classes}) =>
  !loading &&
    <div className={classes.bgImageContainer}>
      <img className={classes.bgImage} src={bg_content} alt="background"/>
    </div>
  ;

MiniContentBackground.propTypes = {
  loading: PropTypes.bool,
  classes: PropTypes.object.isRequired
};

export { MiniContentBackground };
export default MiniContentBackground;