import { useEffect } from 'react';
import { getCachedDataFromQuery, host, mode, getRequestHeaders } from '../utils/api';
import { formatDate } from './utils';
import worker from "../worker";
import WebWorker from "./WebWorker";
import {
  GYAAN_STATUS_RESPONSE_NO_LENGTH,
  GYAAN_STATUS_RESPONSE_OK
} from './constants';
import {setSessionPeriod} from "./utils";

export default (id, period, start, end, comparison=false) => {
  const workerFile = worker;
  return (cbPayload, cbLoading = () => {}, cbStatus = () => {}, cbCache = () => {}) => {
    useEffect(() => {
      let is_request = false;
      // let is_cache = false;
      if ("caches" in window) {
        getCachedDataFromQuery({id_query: id, id_period: period, dt_start: formatDate(start), dt_end: formatDate(end)})
          .then(result => {
            if (result && result.status && result.payload && !is_request) {
              cbStatus(result.status === GYAAN_STATUS_RESPONSE_OK ? GYAAN_STATUS_RESPONSE_OK : GYAAN_STATUS_RESPONSE_NO_LENGTH);
              cbCache(result.payload);
              cbLoading(false);
              // is_cache = true;
            }
          }).catch(err => console.error(err));
      }
      const worker = new WebWorker(workerFile);
      worker.addEventListener('message', e => {
        is_request = true;
        const result = e.data;
        cbStatus(result.status === GYAAN_STATUS_RESPONSE_OK ? GYAAN_STATUS_RESPONSE_OK : GYAAN_STATUS_RESPONSE_NO_LENGTH);
        cbPayload(result.payload || []);
        cbLoading(false);
        setSessionPeriod(id, period);
      });
      const headers = getRequestHeaders();
      worker.postMessage({host, headers, mode, params: {
        id_query: id,
        id_period: period,
        dt_start: formatDate(start),
        dt_end: formatDate(end),
        comparison
      }});
    }, []);
  }
};
